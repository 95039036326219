import SideHeader from './Components/SideHeader/SideHeader'
import { Contact, Experience, Hero, Navbar, Works, } from "./Components/Home";
import Footer from './Components/Footer/Footer';
import { Helmet } from 'react-helmet-async';
import ImageSlider from './Components/ImageSlider/ImageSlider';

function App() {

  
  
  return (
    <>
      <Helmet>
        <title>Mahmut.tech - Home</title>
        <meta name="description" content="This is a javascript homepage for my programming project" />
      </Helmet>
      <SideHeader />
      <div className='relative z-0 bg-gradient-to-r from-[#575353cb] via-[#468b9c] to-[#497884]'>
      {/* background-image: linear-gradient(to right,  0%, #777b7a 15%,  40%, #575353cb 75%,   100%); */}
        
        <div className='bg-hero-pattern bg-cover bg-no-repeat bg-center'>
          <Navbar />
          <Hero />
        </div>
        <ImageSlider />
        <Works />
        <Experience />
        <div className='relative z-0'>
          <Contact />
        </div>
      </div>

      <Footer />
      <br className='sm:hidden flex'/>
      <br  className='sm:hidden flex'/>
    </>
  );
}

export default App;
