import {
  mobile,
  backend,
  web,
  javascript,
  html,
  css,
  reactjs,
  tailwind,
  nodejs,
  meta,
  starbucks,
  tesla,
  shopify,
  carrent,
} from "../assets";

import jobit from '../Images/FireShot Capture 014 - React App - localhost.png'
import azan from '../Images/FireShot Capture 017 - React App - localhost.png'
import tripguide from '../Images/FireShot Capture 018 - React App - localhost.png'



export const navLinks = [
  {
    id: "contact",
    title: "إتصل بنا",
  },

  {
    id: "work",
    title: " العاب",
  },

  {
    id: "works",
    title: "تطبيقات",
  },
  {
    id: "about",
    title: "مقدمة  ",
  },
];



const experiences = [
  {
    title: "الثعبان",
    company_name: "https://mahmud.tech/snake",
    icon: tesla,
    iconBg: "#E6DEDD",
    date: "ذو الحلقات المستديرة",
    points: [
      "",
      " من اجمل الالعاب وامتعها تعمل على الكمبيوتر واللمس في الموبايل ",
    ],
  },


  {
    title: "فوازير من حروف",
    company_name: "https://mahmud.tech/guessword",
    icon: shopify,
    iconBg: "#383E56",
    date: "معلومات عن كل شيئ ",
    points: [
      "",
      " ان كنت من عشاق الفوازير والاحجيات ادخل وجرب معلوماتك هنا",
    ],
  },
  {
    title: "مسابقات اسلامية",
    company_name: "https://mahmud.tech/fuaziir",
    icon: meta,
    iconBg: "#E6DEDD",
    date: "تحدي الوقت ",
    points: [
      "",
      " إدخل واختبر معلوماتك الاسلامية وبإمكانك ترك نتيجتك على مرئ الجميع في صفحة المسابقات إن أردت",
    ],
  },
  {
    title: "لعبة X - O",
    icon: starbucks,
    iconBg: "#383E56",
    date: "بتصميم ثلاثي الابعاد",
    points: [

      " ادخل والعب و استمتع من الكمبيوتر او الموبايل",
    ],
    company_name: "https://mahmud.tech/tictactoe",
  },
];


const projects = [
  {
    name: "مواقيت الصلاة",
    description:
      "نقدم لكم خدمة عرض أوقات الصلاة لليوم الحالي وبحسب موقع الزائر ،مع  توفير الموقع أيضًا لمؤقت عد تنازلي يعرض الوقت المتبقي حتى الصلاة التالية  .",
    tags: [
    
    ],
    image: azan,
    source_code_link: "https://mahmud.tech/azan",
  },
  
  {
    name: " محول العملات",
    description:
      " بامكانك الان معرفه سعر صرف العمله الخاصه ببلدك مقابل اي عملة اجنبية تريد . كما يوجد معلومات مثبتة لاكثر من بلد عن سعر العمله مقابل الدولار",
    tags: [
    ],
    image: jobit,
    source_code_link: "https://mahmud.tech/fuaziir",
  },
  {
    name: "حالة الطقس",
    description:
      "يوفر الموقع للزائرين تنبؤات مفصلة كل ثلاث ساعات على مدار اليوم بحسب موقعك الحالي . بمافيها معلومات عن احتمالية هطول الأمطار وسرعة الرياح والأحوال الجوية الأخرى ",
    tags: [
    ],
    image: tripguide,
    source_code_link: "https://mahmud.tech/weather",
  },
  {
    name: "إسألني",
    description:
      " بامكانك الان التحدث مع الذكاء بشكل مباشر  وسؤاله عما تريد . لاتترد في أي سؤال أو استفسار يخطر ببالك . التجربة مجانية بالكامل.",
    tags: [
    ],
    image: carrent,
    source_code_link: "https://mahmud.tech/chat",
  },
];

export { experiences, projects };