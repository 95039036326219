import Header from "../../Components/Header/Header";
import SideHeader from "../../Components/SideHeader/SideHeader";
import React,{useContext} from "react";
import ReactDOM from "react-dom";
import "../../Components/quiz/index.css";
import Quiz from "../../Components/quiz/components/Quiz";
import { QuizProvider } from "../../Components/quiz/contexts/quiz";
// Bootstrap CSS

// Bootstrap Bundle JS
import { QuizContext } from "../../Components/quiz/contexts/quiz";
import Footer from "../../Components/Footer/Footer";
import { Helmet } from 'react-helmet-async';
import Popup from "../../Components/Popup/Popup";

const Fuaziir = () => {
  const quizState = useContext(QuizContext);

  const content = 
  "طريقه عمل مسابقه . عند البدء تمتلك 30 ثانيه ومع كل إجابه صحيحه يزداد المؤقت 6 ثواني. وعند الاجابه الخاطئه ينقص ثانيتين. وفي نهايه المسابقه تحصل على نتيجة الاختبار.لكل جواب صحيح ثلاث نقاط .وللاجابه خاطئه نقطه واحدة .ويجب ان تنتظر مدة ثانية للانتقال للسؤال التالي .";
  
  const sentences = content.split('.');

  const formattedContent = sentences.map((sentence, index) => (
    <p key={index}>{sentence.trim()}</p>
  ));


  return (
     <>
      <Helmet>
        <title>Mahmud.tech - Fuaziir</title>
        <meta name="description" content="اختبارات إسلامية مسابقات إسلامية مسابقات إسلامية إسلامي يا " />
      </Helmet>
      <Header element={<Popup header="تطبيق مواقيت الصلاة" content={formattedContent}  />} name="إختبارات إسلامية"/>
        <QuizProvider>
          <Quiz />
        </QuizProvider>
        < SideHeader />
       <div id="timer" className="timer sm:w-[250px] sm:text-[18px] text-[17px] w-[100%] text-center right-[-68px] sm:right-[110px] absolute top-[122px] sm:top-[248px]"></div>
       <Popup className="sm:flex hidden" header="مسابقات اسلامية" content={formattedContent}  />
        <Footer />
        <br className="sm:hidden flex"/>
      <br className="sm:hidden flex" />
     </>
  )
}

export default Fuaziir
