import Header from "../../Components/Header/Header";
import SideHeader from "../../Components/SideHeader/SideHeader";
import "./Quran.css";
import Footer from "../../Components/Footer/Footer";
import { Helmet } from 'react-helmet-async';
import { FaMicrophoneAlt } from "react-icons/fa";
import { useEffect } from "react";
import Popup from "../../Components/Popup/Popup";


const Quran = () => {
  const getData = () => {
    document.getElementById("a").innerHTML +=
      '<h1 id="h1" style="text-align:center">... جاري ارسال الرد </h1>';
    const question = document.getElementById("question");
    fetch(`https://memo2009.pythonanywhere.com/ask?q=${question.value}`)
      .then((res) => res.json())
      .then((data) => {
        document.querySelectorAll("#h1").forEach((item) => {
          console.log(item);
          item.style.display = "none";
        });
        if (question.value !== "") {
          document.getElementById("a").innerHTML += `
                <div style="display:flex; gap: 10px;align-items: center;padding:10px" dir="rtl">

                  <h2 style="color:#ffffff">  ${question.value} </h2>
                </div>`;
          document.getElementById("a").innerHTML += `
                <div dir="rtl" style="display:flex;gap: 10px;align-items: center;background-color:#999595; padding:10px">

                  <h2>  ${data.answers} </h2>
                </div>`;
          question.value = "";
        } else {
          alert("لا يمكن ارسال رسالة فارغة");
        }
      });
  };

  const content = 
  "خدمة التحدث مع الذكاء الاصطناعي في موقعنا .هي واحدة من عدة خدمات جمعناها لك في مكان واحد .بامكانك الان التعلم ومخاطبه الذكاء الاصطناعي شات جي بي تي من خلال موقعنا بكل سهولة .لا داعي للتسجيل والاشتراك فقط قم بالدخول وإسال الذكاء الاصطناعي عما يخطر ببالك .  لا تنسى -بإمكانك أن تدعمنا بشكل مجاني من خلال الإشتراك بقنواتنا على شبكات التواصل";
    
    const sentences = content.split('.');
  
    const formattedContent = sentences.map((sentence, index) => (
      <p key={index}>{sentence.trim()}</p>
    ));
  useEffect(() => {
    const speechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition

  if (speechRecognition) {
    console.log("it support");

    const recognition = new speechRecognition()
    recognition.continuous = true
    recognition.lang = "ar"
    
    

    console.log(document.getElementById("record"))

    document.getElementById("record").addEventListener("click",(eo) => {
      if (document.getElementById("record").classList.contains("icon")) { // start
        document.getElementById("record").classList.remove("icon")
        document.getElementById("record").classList.add("sa")
        recognition.start()
      }else{ // stop
        document.getElementById("record").classList.remove("sa")
        document.getElementById("record").classList.add("icon")
        recognition.stop()
      }

      // start
      recognition.addEventListener("start",(eo) => {
        console.log("active");
      })

      // end
      recognition.addEventListener("end",(eo) => {
        console.log("end");
      })

    //   // result
      recognition.addEventListener("result",(result) => {
        console.log(result)
        const resultss = result.results[0][0].transcript
        document.getElementById("question").value = resultss
      })
    })
  }else{
    console.log("not support");
  }
  }, []);

  

  return (
    <div>
      <Helmet>
        <title>Mahmud.tech - AI</title>
        <meta name="description" content="تحدث مع الذكاء الاصطناعي ,دردشه مع الذكاء الاصطناعي, تعلم مع الذكاء الاصطناعي شات GPT, CHAT GPT" />
      </Helmet>
      <Header element={<Popup header="تطبيق مواقيت الصلاة" content={formattedContent}  />} name="إســألني" />
      <SideHeader />
      <div className="containerA">
        <div className="boxC">
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>

          <div className="container">
            <div id="a"></div>
            <div className="form sm:ml-[0px]">
              <div className="input-group">
                <button className="icon" id="record"><FaMicrophoneAlt /></button>
                <input
                  type="text"
                  className="inputs rounded"
                  id="question"
                  name="Email"
                  dir="rtl"
                  placeholder="اكتب سؤالك هنا  ... "
                />
                <input
                  className="button--submit"
                  id="submit"
                  value="أرسل"
                  onClick={getData}
                  type="submit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup className="sm:flex hidden" header="شات GPT" content={formattedContent} />
      <Footer />


    </div>
  );
};

export default Quran;
