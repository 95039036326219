import React from 'react'
import Lottie from 'lottie-react'
import man from "../../../Lottie/95348-coding-boy.json"

const Earth = () => {
  return (
    <div><Lottie animationData={man} /></div>
  )
}

export default Earth