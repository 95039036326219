import Header from "../../Components/Header/Header";
import SideHeader from "../../Components/SideHeader/SideHeader";
import { Helmet } from 'react-helmet-async';

const Portfolio = () => {
  return (
    <>
      <Helmet>
        <title>Mahmut.tech - Home</title>
        <meta name="description" content="This is a javascript portfolyo page for my programming project" />
      </Helmet>
      <Header  name="Azan"/>
      <SideHeader />
    </>
  )
}

export default Portfolio
