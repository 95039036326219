import React, { createContext, useReducer } from "react";
import questions from "../data";
import { shuffleAnswers } from "../helpers";


const initialState = {
  questions,
  currentQuestionIndex: 0,
  currentAnswer: "",
  answers: shuffleAnswers(questions[0]),
  showResults: false,
  correctAnswersCount: 0,
};

// ! variables
var timeLeft = 2900;
let correctAnswersCount;
let qIndex = 0



// ! timer
setTimeout(() => {
  var elem = document.getElementById('timer');
  var timerId = setInterval(countdown, 10);
  var body = document.querySelector('body');

  function countdown() {
    if (timeLeft < 1) {
      clearTimeout(timerId);
      window.location.href = '/sonuc'
    } else {
      if (elem != null) {
        elem.innerHTML = `<span class="text-red-900"> ${timeLeft} </span> - المؤقت `;
        timeLeft--;
      }
    }
  }
}, 100)


// ! checking
const reducer = (state, action) => {
  switch (action.type) {
    case "SELECT_ANSWER": {
        if (action.payload === state.questions[state.currentQuestionIndex].correctAnswer) {
          const sound = '/sounds/correct-2-46134.mp3'
          const playSound = new Audio(sound)
          playSound.play()
          timeLeft = timeLeft + 500
          correctAnswersCount = state.correctAnswersCount + 1
          qIndex = qIndex + 1
          localStorage.setItem('correctAnswersCount', correctAnswersCount);
          localStorage.setItem('qIndex', qIndex);
          
        }else{
          const sound = '/sounds/wrong-answer-126515.mp3'
          const playSound = new Audio(sound)
          playSound.play()
          timeLeft = timeLeft - 300
          correctAnswersCount = state.correctAnswersCount;
          qIndex = qIndex + 1
          console.log(qIndex)
          localStorage.setItem('correctAnswersCount', correctAnswersCount);
          localStorage.setItem('qIndex', qIndex);
        }
      return {
        ...state,
        currentAnswer: action.payload,
        correctAnswersCount,
      };
    }
    case "NEXT_QUESTION": {
      // timeLeft = 2700
      const showResults =
        state.currentQuestionIndex === state.questions.length - 1;
      const currentQuestionIndex = showResults
        ? state.currentQuestionIndex
        : state.currentQuestionIndex + 1;
      const answers = showResults
        ? []
        : shuffleAnswers(state.questions[currentQuestionIndex]);
      return {
        ...state,
        currentAnswer: "",
        showResults,
        currentQuestionIndex,
        answers,
        
      };
    }
    case "RESTART": {
      localStorage.setItem('correctAnswersCount', 0);
      localStorage.setItem('qIndex', 0);
      return initialState;
    }
    default:
      return state;
  }
};

export const QuizContext = createContext();

export const QuizProvider = ({ children }) => {
  const value = useReducer(reducer, initialState);

  return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>;
};
