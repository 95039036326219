import Header from '../Header/Header';
import Popup from '../Popup/Popup';
import SideHeader from '../SideHeader/SideHeader';
import './Paper.css';
import { useEffect } from 'react';

function Paper() {

  useEffect(() => {
    const gameContainer = document.querySelector(".containers"),
      userResult = document.querySelector(".user_result img"),
      cpuResult = document.querySelector(".cpu_result img"),
      result = document.querySelector(".result"),
      optionImages = document.querySelectorAll(".option_image");
    optionImages.forEach((image, index) => {
      image.addEventListener("click", (e) => {
        image.classList.add("active");
        userResult.src = cpuResult.src = "images/rock.png";
        result.textContent = "Wait...";
        // Loop through each option image again
        optionImages.forEach((image2, index2) => {
          index !== index2 && image2.classList.remove("active");
        });
        gameContainer.classList.add("start");
        setTimeout(() => {
          gameContainer.classList.remove("start");
          let imageSrc = e.target.src;
          console.log(e.target)
          userResult.src = imageSrc;
          let randomNumber = Math.floor(Math.random() * 3);

          let cpuImages = ["images/rock.png", "images/paper.png", "images/scissors.png"];

          cpuResult.src = cpuImages[randomNumber];

          let cpuValue = ["R", "P", "S"][randomNumber];
          let userValue = ["R", "P", "S"][index];
          let outcomes = {
            RR: "Draw",
            RP: "Cpu",
            RS: "User",
            PP: "Draw",
            PR: "User",
            PS: "Cpu",
            SS: "Draw",
            SR: "Cpu",
            SP: "User",
          };

          let outComeValue = outcomes[userValue + cpuValue];
          result.textContent = userValue === cpuValue ? "Match Draw" : `${outComeValue} Won!!`;
        }, 2500);
      });
    });
  }, []);

  return (
<>
    <SideHeader />
    <Header  />
      <div className='center'>
        <section class="containers">
          <div class="result_field">
            <div class="result_images">
              <span class="user_result">
                <img src="images/rock.png" alt="" />
              </span>
              <span class="cpu_result">
                <img src="images/rock.png" alt="" />
              </span>
            </div>
            <div class="result">let Play</div>
          </div>
          <div class="option_images">
            <span className='option_imager'>
              <img class="option_image" src="images/rock.png" alt="" />
              <p>rock</p>
            </span>
            <span className='option_imager'>
              <img class="option_image" src="images/paper.png" alt="" />
              <p>paper</p>
            </span>
            <span className='option_imager'>
              <img class="option_image" src="images/scissors.png" alt="" />
              <p>scissors</p>
            </span>
          </div>
          <Popup content="Paper Game" />
        </section>
      </div>
</>
  );
}

export default Paper;
