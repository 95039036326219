import React from 'react'
import './Header.css'
import {NavLink} from 'react-router-dom'
import SocialIcons from '../SocialIcons/SocialIcons'
import logo from '../../Images/مشروع-جديد-4.png'

const Header = (props) => {
  const iconF = <i className="fa-brands fab fa-facebook-f"></i>
  const iconY = <i className="fab fa-youtube" aria-hidden="true"></i>
  const iconI = <i className="fab fa-instagram" aria-hidden="true"></i>

  return (
    <>
      <div className="header">
        <div className="logo">
          <img className="sm:flex hidden" src={logo} height='72px' width='72px' alt="" />
          <div className="flex sm:hidden">
            {props.element}
          </div>
        </div>

        <div className='socialIcons sm:flex hidden'>
          <div className="barItem">
            <NavLink
              className="link"
              style={isActive => ({
                color: isActive ? "green" : "blue"
              })}
              to="/"
            >
              <div className="box">
                <SocialIcons icon={iconF}/>
              </div>
            </NavLink>
          </div>
          
          <div className="barItem">
            <NavLink
              className="link"
              style={isActive => ({    
                color: isActive ? "green" : "blue"
              })}
              to="/"
            >
              <div className="box">
                <SocialIcons icon={iconI}/>
              </div>
            </NavLink>
          </div>
          
          <div className="barItem">
            <NavLink
              className="link"
              style={isActive => ({
                color: isActive ? "green" : "blue"
              })}
              to="/"
            >
              <div className="box">
                <SocialIcons icon={iconY}/>
              </div>
            </NavLink>    
          </div>
        </div>

        <div className="textS">
        <p>
          <h3 class='insetshadow'>
            {props.name}
          </h3>
        </p>
        </div>
      </div>
    </>
  )
}

export default Header
