import React from "react";
import { Tilt } from "react-tilt";
import { motion } from "framer-motion";

import { styles } from "../../styles";
import { github } from "../../assets";
import { SectionWrapper } from "../../hoc";
import { projects } from "../../constants";
import { fadeIn, textVariant } from "../../utils/motion";

const ProjectCard = ({
  index,
  name,
  description,
  tags,
  image,
  source_code_link,
}) => {
  return (
    <motion.div variants={fadeIn("up", "spring", index * 0.5, 0.75)}>
      <Tilt
        options={{
          max: 45,
          scale: 1,
          speed: 450,
        }}
        style={{background: "linear-gradient(to right, #3630857c, #498196cc, #7a95a3)",boxShadow: "4px 4px 8px #282626, -4px -4px 15px #fdfdfd00",  border: "2.5px solid rgba(177, 171, 171, 0.818)"}}
        className="p-5 rounded-2xl sm:w-[360px] w-full"
      >
        <div className="relative w-full h-[230px]">
          <img
            src={image}
            alt="project_image"
            className="w-full h-full object-cover rounded-2xl"
            style={{border: "1px solid #000000"}}
          />

          <div className="absolute inset-0 flex justify-start m-3 card-img_hover">
            <div
              onClick={() => window.open(source_code_link, "_blank")}
              className="black-gradient w-10 h-10 rounded-full flex justify-center items-center cursor-pointer"
              style={{border: "0.5px solid #ffffff"}}
            >
              <img
                src={github}
                alt="source code"
                className="w-1/2 h-1/2 object-contain"
                
              />
            </div>
          </div>
        </div>

        <div className="mt-5">
          <h3 className="text-black font-bold text-[26px]">{name}</h3>
          <p className="mt-2 text-[#ECEEEF] text-[15px]">{description}</p>
        </div>

        <div className="mt-4 flex flex-wrap gap-2">
          {tags.map((tag) => (
            <p
              key={`${name}-${tag.name}`}
              className={`text-[14px] ${tag.color}`}
            >
              #{tag.name}
            </p>
          ))}
        </div>
      </Tilt>
    </motion.div>
  );
};

const Works = () => {
  return (
    <div dir="rtl">
      <motion.div className="text-center" variants={textVariant()}>
        <h2 className={`${styles.sectionHeadText}`}>  مشروع 4✖4</h2>
      </motion.div>

      <div className="w-full flex justify-center">
        <motion.p
          variants={fadeIn("", "", 0.1, 1)}
          className="mt-3 text-black text-[20px] max-w-3xl leading-[30px] text-center"
        >
           هو عباره عن مشروع يضم قسمين رئيسين قسم لأربع تطبيقات خدميه و قسم لأربع ألعاب و جميعها مبنية بلغة الجافا سكربت و مكتباتها الكثيرة واهمها مكتبة الرياكت 
        </motion.p>
      </div>

      <div className="mt-20 flex flex-wrap gap-7">
        {projects.map((project, index) => (
          <ProjectCard key={`project-${index}`} index={index} {...project} />
        ))}
      </div>
    </div>
  );
};

export default SectionWrapper(Works, "works");