import { useState } from "react";
import "./GuessWord.css";
import { useEffect } from "react";
import SideHeader from "../SideHeader/SideHeader";
import Popup from "../Popup/Popup";
import Header from "../Header/Header";
import { Helmet } from 'react-helmet-async';


function Guess() {
  const wordList = [
    {
      word: "البحرين",
      hint: "ما هي أصغر دولة عربية من حيث المساحة ؟",
      catagory: "sting"
    },
    {
      word: "لندن",
      hint: "  ما هي المدينة التي تسمى بمدينة الضباب  ؟" ,
      catagory: "sting"

    },
    {
      word: "البصلة",
      hint: "ما هو الشيء الذي تذبحه و تبكي عليه ؟",
      catagory: "sting"

    },
    {
      word: "المقص ",
      hint: "شيء ليس بإمكانه القطع إلا عند وضع أصبعك في عينيه ؟",
      catagory: "sting"
    },
    {
      word: "المسمار",
      hint: "شيء  لا يمشي إلا بالضرب ؟",
      catagory: "sting"
    },
    {
      word: "التليفون",
      hint: " ما هو الشيء الذي يستطيع أن يسمع دون أن يملك إذن ويستطيع أن يتكلم دون أن يملك فم ؟",
      catagory: "sting"
    },
    {
      word: "والدك",
      hint: "من هو الشخص الذي يكون خال جميع أبناء عمتك وليس عمك ؟ ",
      catagory: "sting"
    },
    {
      word: "المسمار",
      hint: "ما هو الشيء الذي لا يمشي إلا إذا قمنا بضربه ؟",
      catagory: "sting"
    },
    {
      word: "الساعة",
      hint: "ما هو الشيء الذي يستطيع أن يمشي ويقف دون أن يملك الرجل ؟",
      catagory: "sting"
    },
    {
      word: "الضوء",
      hint: "ما هو الشيء الذي لا يبتل حتى لو نزل إلى الماء ؟",
      catagory: "sting"
    },
    {
      word: "الفحم",
      hint: "ما هو الشيء عندما يكون لونه اسود لا يكون نافع ولكن عندما يكون لونه احمر يكون نافع ؟",
      catagory: "sting"
    },
    {
      word: "البرتقال",
      hint: "ما هو الشيء الذي اسمه مثل لونه؟",
      catagory: "sting"
    },
    {
      word: "الثلج",
      hint: "ما هو الشيء الذي يكون ابن الماء ولكن إذا وضع في الماء مات ؟",
      catagory: "sting"
    },
    {
      word: "نجم",
      hint: "ما هو الشيء الذي يوجد في السماء وإذا أضفنا إليه حرفا أصبح شيء يوجد في الأرض ؟",
      catagory: "sting"
    },
    {
      word: "الأبجدية",
      hint: "ما هي الكلمة التي تتكون من 8 حروف ولكنها تجمع كل الحروف ؟",
      catagory: "sting"
    },
    {
      word: "أنا",
      hint: "من هو الشخص الذي يكون ابن أمك وأبيك ولكن ليس أخيك ؟ ",
      catagory: "sting"
    },
    {
      word: "اندونيسيا",
      hint: "ما هى الدولة التي يوجد بها أكبر عدد من البراكين في العالم ؟",
      catagory: "sting"
    },
    {
      word: "بوليفيا",
      hint: "ما هى الدولة التي لعبت الكثير من المباريات في كأس العالم ولكنها لم تفز بأي مباراة منها ؟ ",
      catagory: "sting"
    },
    {
      word: "الأعداد",
      hint: "ما هي الأشياء التي كلما ضربت كبرت ؟",
      catagory: "sting"
    },
    {
      word: "المقص",
      hint: "ما هو الشيء الذي لا يكون نافع إلى إذا قمنا بوضع اصبعنا في عينيه؟",
      catagory: "sting"
    },
    {
      word: "الهند",
      hint: "ما هي الدولة التي يوجد بها أكبر عدد من الجامعات؟",
      catagory: "sting"
    },
    {
      word: "القاموس",
      hint: "ما هو المكان الذي يأتي فيه يوم الجمعة قبل يوم الخميس؟",
      catagory: "sting"
    },
    {
      word: "الضوء",
      hint: "ما هو الشيء الذي يستطيع أن يعبر مو الزجاج دون أن يكسره؟",
      catagory: "sting"
    },
    {
      word: "الإبرة",
      hint: "ما هو الشيء الذي يملك عين ولا يرى وليس له رأس؟ ",
      catagory: "sting"
    },
    {
      word: "الكتاب",
      hint: "ما هو الشيء الذي يمتلك أوراق ولكنه ليس نبات وله جلد ولكن ليس بحيوان و يعلم ولكنه ليس إنسان؟",
      catagory: "sting"
    ,
    },
    {
      word: "الجوع",
      hint: "ما الشيء الذي يستطيع أن يقرصك ولكنك لا تراه؟",
      catagory: "sting"
    },
    {
      word: "الدبوس",
      hint: "ما الشيء الذي كلما اخذنا منه زاد؟ الحفرة. ما هو الشيء الذي له رأس ولكن ليس له عين؟",
      catagory: "sting"
    },
    {
      word: "فلفل",
      hint: "ما هو الشيء حتى لو وضع في الثلاجة لا يبرد؟",
      catagory: "sting"
    },
    {
      word: "الأنف",
      hint: "ما الشيء الذي لحمه يوجد بالخارج وشعره يوجد بالداخل؟",
      catagory: "sting"
    },
    {
      word: "الوطواط",
      hint: "ما هو الطائر الذي يلد ولكنه لا يبيض؟",
      catagory: "sting"
    },
    {
      word: "الظل",
      hint: "شيء إن وضعت عليه الماء لا يبتل.",
      catagory: "sting"
    },
    {
      word: "الطبق",
      hint: "تأكل منه عادي، ولكن لا تستطيع أن تأكله.",
      catagory: "sting"
    },
    {
      word: "النار",
      hint: "تآكل ولا تشبع فما هي؟.",
      catagory: "sting"
    },
    {
      word: "الغيوم",
      hint: "من هو الذي يسير ولكن ليس له أقدام، ويبكي ولكنه ليس له عيون، ويطير بلا أجنحة؟",
      catagory: "sting"
    },
    {
      word: "الأعور",
      hint: "شخص يرى عدوه ويرى صديقه بعين واحدة.",
      catagory: "sting"
    },
    {
      word: "القاف",
      hint: "ما هذا الشيء الذي يوجد في الدقيقة مرتين، وغير موجود في الساعة.",
      catagory: "sting"
    },
    {
      word: "البيض",
      hint: "كي تستفيد منه يجب أن تكسره.",
      catagory: "sting"
    },
    {
      word: "البطيخ",
      hint: "قلعة خضراء، شعبها يرتدي اللون الأسود، وأرضها حمراء.",
      catagory: "sting"
    },
    {
      word: "الديك",
      hint: "له تاج وليس بملك ويدلك على الوقت فما هو ؟",
      catagory: "sting"
    },
    {
      word: "البومة",
      hint: "ما هو أكثر الطيور ذكاء؟",
      catagory: "sting"
    },
    {
      word: "الشمعة",
      hint: "تقوم بحرق نفسها من اجل ان يستفيد الآخرون ، فمن هي ؟",
      catagory: "sting"
    },
    {
      word: "القاف",
      hint: "ما هذا الشيء الذي يوجد في الدقيقة مرتين، وغير موجود في الساعة.",
      catagory: "sting"
    },
    {
      word: "الحفرة",
      hint: "شيء كلما أخذنا منه كبر.",
      catagory: "sting"
    },
    {
      word: "الخس",
      hint: "نوع من الخضار ، الوحيد الذي لا يباع مثلج أو معلب أو مطبوخ ولكن فقط يؤكل طازج ، فمن يكون ؟ ",
      catagory: "sting"
    },
    {
      word: "التين",
      hint: "ما هي اسم الفاكهة التي سُميت بإحدى سور القرآن  الكريم باسمها ؟",
      catagory: "sting"
    },
    {
      word: "الفراولة",
      hint: "ما هي الفاكهة الوحيدة التي ليس لها أوراق في شجرها ؟",
      catagory: "sting"
    },
    {
      word: "الضفدع",
      hint: "أي حيوان يسمى صوته نقيق ؟",
      catagory: "sting"
    },
    {
      word: "الزرافة",
      hint: "ما هو الحيوان الأبكم الذي لا يتكلم ولا نسمع له صوت ؟",
      catagory: "sting"
    },
    {
      word: "النوم",
      hint: "اغلبك دون أن أؤذيك .. فمن أنا ؟",
      catagory: "sting"
    },
    {
      word: "تموز",
      hint: "شهر ميلادي إذا حذفت أول حرف منه أصبح فاكهة؟",
      catagory: "sting"
    },
    {
      word: "الحذاء",
      hint: "لا قيمة لأحدهما دون الآخر؟",
    },
    {
      word: "الدائرة",
      hint: "شيء ليس له بداية ولا نهاية ما هو هذا الشيء؟",
      catagory: "sting"
    },
    {
      word: "الصدى",
      hint: "ما الذي لا يستطيع الكلام ولكنه سوف يرد عند التكلم إليه؟",
      catagory: "sting"
    },
    {
      word: "السماء",
      hint: "ما هو الشيء الذي في نصفه الأول موت الإنسان، والنصف الآخر سبب الحياة؟",
      catagory: "sting"
    },
    {
      word: "النعامة",
      hint: "ما هو الحيوان الذي عينه أكبر من دماغه؟",
      catagory: "sting"
    },
    {
      word: "128",
      hint: "أحمد شاب كريم للغاية، اشترى صندوق تفاح ومر على سبعة من أصدقائه، وكلما مر على صديق منهم أعطاه نصف ما معه من صندوق التفاح،ولما عاد أحمد إلى منزله وجد ما تبقى معه هو تفاحة واحدة فقط..فكم عدد التفاح الذي كان معه في الصندوق؟",
      catagory: "number"
    },
    {
      word: "8",
      hint: "قطار طوله 1 كيلو متر، يجب أن يجتاز نفق طوله 1 كيلو متر أيضًا، ما هو عدد الدقائق الذي يحتاجها القطار ليجتاز هذا النفق علما بأنه يسير بسرعة 15 كيلومترًا في الساعة؟!",
      catagory: "number"
    },
    {
      word: "1000",
      hint: "قال محمود ل اخوه محمد : راتبي يساوي نفس راتبك، وقيمة راتبك تساوي ضعف قيمة راتبي ناقص 1000 جنيه.. تُرى كم راتب كل منهما؟!",
      catagory: "number"
    },
    {
      word: "3",
      hint: " 3قطط أمسكوا بـ 3 فئران في 3 دقائق فقط، كم عدد القطط المطلوبة لإصطياد 100 فأر في 100 دقيقة؟!",
      catagory: "number"

    },
    {
      word: "3840",
      hint: "ما هو ناتج ضرب الأعداد الزوجية من 2 إلى 10؟",
      catagory: "number"
    },
    {
      word: "5050",
      hint: "ما هو مجموع جميع الأعداد الطبيعية من 1 إلى 100؟",
      catagory: "number"
    },
    {
      word: "18",
      hint: "ما هو الناتج العددي للتعبير 3 × (8 - 4) + 6 ÷ 2؟",
      catagory: "number"
    },
    {
      word: "100",
      hint: "ما هو ناتج جمع الأعداد الفردية من 1 إلى 20؟",
      catagory: "number"
    },
    {
      word: "4",
      hint: "ما هو عدد الأضلاع في الهرم ذو القاعدة الثلاثية؟",
      catagory: "number"
    },
    {
      word: "12",
      hint: "ما هو الناتج العددي للجذر التربيعي للعدد 144؟",
      catagory: "number"
    },
    {
      word: "106",
      hint: "ما هو المجموع عند جمع الأعداد الأولية من 1 إلى 20؟",
      catagory: "number"
    },
    {
      word: "210",
      hint: "ما هو ناتج ضرب الأعداد الأولية بين 1 و 10؟",
      catagory: "number"
    },
    {
      word: "25",
      hint: "ما هي النسبة المئوية للعدد 75 من العدد 300؟",
      catagory: "number"
    },
    {
      word: "1275",
      hint: "ما هو المجموع عند جمع الأعداد من 1 إلى 50؟",
      catagory: "number"
    },
    {
      hint: "منذ أربع سنوات كان عمر أحمد ضعف عمر محمد وبعد أربع سنوات أصبح محمد يبلغ ثلاثة أرباع عمر أحمد، فما هو عمر أحمد؟",
      word: "12",
      catagory: "number"
    },
    {
      word: "141",
      hint: "ما هو الرقم الذي يتكون من ثلاثة أعداد العدد الثاني أربع أضعاف العدد الثالث والعدد الأول أصغر بثلاث مرات من العدد الثاني، فما هو العدد؟",
      catagory: "number"
    },
    {
      word: "15",
      hint: "إذا كان ضعف العدد الفردي يساوي 30، فما هو العدد الفردي؟",
      catagory: "number"
    },
    {
      word: "20",
      hint: "ما هو العدد الذي إذا قسمته على 2 وجمعت الناتج مع 10 ستحصل على الناتج نفسه؟",
      catagory: "number"
    },
    {
      word: "24",
      hint: "لدي ثلاثة أعداد متتالية. إذا كان مجموع الأعداد الثلاثة يساوي 72، فما هو العدد الوسط؟",
      catagory: "number"
    },
    {
      word: "5",
      hint: "ما هو العدد الذي إذا ضربته في نفسه وجمعت الناتج معه ستحصل على 30؟",
      catagory: "number"
    },
    {
      word: "40",
      hint: "ما هو العدد الذي إذا قسمته على 5 وضربت الناتج في 8 ستحصل على 32؟",
      catagory: "number"
    },
    {
      word: "27",
      hint: "إذا كان ثلث العدد يساوي 9، فما هو العدد؟",
      catagory: "number"
    },
    {
      word: "36",
      hint: "ما هو العدد الذي إذا قسمته على 3 وضربت الناتج في 4 ستحصل على 48؟",
      catagory: "number"
    },
    {
      word: "42",
      hint: "ما هو العدد الذي إذا قسمته على 7 وضربت الناتج في 6 ستحصل على 36؟",
      catagory: "number"
    },
    {
      word: "16",
      hint: "إذا كان خمسة أمثال العدد تساوي 80، فما هو العدد؟",
      catagory: "number"
    },
    {
      word: "12",
      hint: "ما هو العدد الذي إذا ضربته في 4 وقسمت الناتج على 3 ستحصل على 16؟",
      catagory: "number"
    },
    {
      word: "القاهرة",
      hint: "ما هي عاصمة جمهورية مصر العربية؟",
      catagory: "sting"
    },
    {
      word: "أبو ظبي",
      hint: "ما هي عاصمة دولة الإمارات العربية المتحدة؟",
      catagory: "sting"
    },
    {
      word: "مسقط",
      hint: "ما هي عاصمة دولة سلطنة عمان؟",
      catagory: "sting"
    },
    {
      word: "الدوحة",
      hint: "ما هي عاصمة دولة قطر؟",
      catagory: "sting"
    },
    {
      word: "الرياض",
      hint: " ما هي عاصمة دولة السعودية؟",
      catagory: "sting"
    },
    {
      word: "مدريد",
      hint: "ما هي عاصمة دولة اسبانيا؟",
      catagory: "sting"
    },
    {
      word: "روما",
      hint: "ما هي عاصمة دولة ايطاليا؟",
      catagory: "sting"
    },
    {
      word: "برلين",
      hint: "ما هي عاصمة دولة المانيا؟",
      catagory: "sting"
    },
    {
      word: "باريس",
      hint: "ما هي عاصمة دولة فرنسا؟",
      catagory: "sting"
    },
    {
      word: "الرباط",
      hint: "ما هي عاصمة دولة المغرب؟",
      catagory: "sting"
    },
    {
      word: "كانبرا",
      hint: "ما هي عاصمة استراليا؟",
      catagory: "sting"
    },
    {
      word: "اوتاوا",
      hint: "ما هي عاصمة كندا؟",
      catagory: "sting"
    },
    {
      word: "برن",
      hint: "ما هي عاصمة دولة سويسرا؟",
      catagory: "sting"
    },
    {
      word: "بكين",
      hint: "ما هي عاصمة دولة الصين؟",
      catagory: "sting"
    },
    {
      word: "سيول",
      hint: "ما هي عاصمة دولة كوريا؟",
      catagory: "sting"
    },
    {
      word: "مدريد",
      hint: " ما هي عاصمة دولة اسبانيا؟",
      catagory: "sting"
    },
    {
      word: "أثينا",
      hint: "ما هي عاصمة دولة اليونان؟",
      catagory: "sting"
    },
    {
      word: "نيودلهي",
      hint: "ما هي عاصمة دولة الهند؟",
      catagory: "sting"
    },
    {
      word: "برازيليا",
      hint: "ما هي عاصمة دولة البرازيل؟",
      catagory: "sting"
    },
    {
      word: "روسيا",
      hint: " أين تقع جبال الأورال؟",
      catagory: "sting"
    },
    {
      word: "طوكيو",
      hint: "ما هي عاصمة اليابان؟",
      catagory: "sting"
    },
    {
      word: "الأردن",
      hint: "أين تقع البتراء؟",
      catagory: "sting"
    },
    {
      word: "العراق",
      hint: "أين تقع حدائق بابل المعلقة؟",
      catagory: "sting"
    },
    {
      word: "مصر",
      hint: "أين تقع مدينة الغردقة؟",
      catagory: "sting"
    },
    {
      word: "صقلية",
      hint: "ما هي أكبر وأوسع جزر البحر المتوسط؟",
      catagory: "sting"
    },
    {
      word: "المكسيك",
      hint: "ما هو أكبر خليج في العالم؟",
      catagory: "sting"
    },
    {
      word: "محمود",
      hint: "ماهو اسم مبرمج اللعبة ",
      catagory: "sting"
    },
    {
      word: "النيل",
      hint: "أكبر نهر في العالم",
      catagory: "sting"
    },
  
  ];

  const [randomWord, setrandomWord] = useState("");
  const [divrtl, setdivrtl] = useState("");

  const [isTrue, setisTrue] = useState(null);
  const [score, setscore] = useState(0);

  useEffect(() => {
    const inputs = document.querySelector(".inputss"),
    hintTag = document.querySelector(".hint span"),
    guessLeft = document.querySelector(".guess-left span"),
    wrongLetter = document.querySelector(".wrong-letter span"),
    resetBtn = document.querySelector(".reset-btn"),
    typingInput = document.querySelector(".typing-input");
    let word, maxGuesses, incorrectLetters = [], correctLetters = [];
    function randomWord() {
        let ranItem = wordList[Math.floor(Math.random() * wordList.length)];
        // console.log(ranItem);
        setrandomWord(ranItem)
        word = ranItem.word;
        maxGuesses = word.length >= 5 ? 8 : 6;
        correctLetters = []; incorrectLetters = [];
        hintTag.innerText = ranItem.hint;
        guessLeft.innerText = maxGuesses;
        wrongLetter.innerText = incorrectLetters;
        let html = "";
        for (let i = 0; i < word.length; i++) {
            html += `<input type="text" disabled>`;
            inputs.innerHTML = html;
        }
    }
    randomWord();
    function initGame(e) {
        let key = e.target.value.toLowerCase();
        if(key.match(/^([A-Za-z\u0600-\u06FF0-9])+$/) && !incorrectLetters.includes(` ${key}`) && !correctLetters.includes(key)) {
            if(word.includes(key)) {
                for (let i = 0; i < word.length; i++) {
                    if(word[i] == key) {
                        correctLetters += key;
                        inputs.querySelectorAll("input")[i].value = key;
                    }
                }
            } else {
                maxGuesses--;
                const sound = '/sounds/wrong-answer-126515.mp3'
                const playSound = new Audio(sound)
                playSound.play()
                incorrectLetters.push(` ${key}`);
            }
            guessLeft.innerText = maxGuesses;
            wrongLetter.innerText = incorrectLetters;
        }
        typingInput.value = "";
        setTimeout(() => {
            if(correctLetters.length === word.length) {
                alert(`الاجابة الصحيحة " ${word.toUpperCase()} "`)
                const sound = '/sounds/correct-2-46134.mp3'
                const playSound = new Audio(sound)
                playSound.play()
                setisTrue(true)
                // setTimeout(() => {
                // }, 1000)
                
                return randomWord();
            } else if(maxGuesses < 1) {
                setisTrue(false)
                alert("Game over! You don't have remaining guesses");
                for(let i = 0; i < word.length; i++) {
                    inputs.querySelectorAll("input")[i].value = word[i];
                }
            }

        }, 1000);
        setTimeout(() => {
                  setisTrue(null)
                  
                }, 2000)
    }
    resetBtn.addEventListener("click", randomWord);
    typingInput.addEventListener("input", initGame);
    inputs.addEventListener("click", () => typingInput.focus());
    document.addEventListener("keydown", () => typingInput.focus());
    
  }, []);


  useEffect(() => {
    if (isTrue == true) {
      setscore(score + 3)
    }else if (isTrue == false) {
      setscore(score - 2)
    }
  }, [isTrue]);

  useEffect(() => {
    console.log(randomWord)
    if (randomWord.catagory == "sting") {
      setdivrtl("rtl")
      // alert("s") 

    }else{
      setdivrtl("ltr")
      // alert("n")

    }
  }, [randomWord]);

  // ! copy

  const content = "ان كنت من عشاق الفوازير و الاحجيات .بامكانك الان تجربه لعبتنا المميزة. حيث الاجوبه فيها من الحروف او الارقام . ومع كل اجابه صحيحه يزداد رصيدك ثلاث نقاط .ومع كل اجابتك خاطئه ينقص رصيدك نقطتين .وهي واحده من عده العاب جمعناها لكم في موقعنا . لا تنسى-بإمكانكم أن تدعمونا بشكل مجاني من خلال الإشتراك بقنواتنا على شبكات التواصل";

  const sentences = content.split('.');

  const formattedContent = sentences.map((sentence, index) => (
    <p key={index}>{sentence.trim()}</p>
  ));

  return (
    <> 
    <Helmet>
        <title>Mahmud.tech - GuessWord</title>
        <meta name="description" content="مواقيت الصلاة ، صلاة الفجر ، صلاة الظهر ، صلاة العصر ، صلاة المغرب ، صلاة العشاء ، صلاة الفجر" />
      </Helmet>
    <SideHeader />
    <Header element={<Popup header="تطبيق مواقيت الصلاة" content={formattedContent}  />} name="لعبة الفوازير"/>
    <div dir="rtl" className="center">
        <div class="wrapper">
          <div className="flex justify-center">
            <h1>النتيجة : {score}</h1>
          </div>
          <div class="content">
            <input dir={divrtl} style={{color:"green"}} type="text" class="typing-input" maxlength="1" />
            <div dir={divrtl} style={{color:"green"}} class="inputss"></div>
            <div class="details">
              <p class="hint">
                السؤال : <span></span>  
              </p>
              <p class="guess-left">
                عدد التخمينات المسموح بها : <span></span>  
              </p>
              <p class="wrong-letter">
                الاجابات الخاطئة : <span style={{color:"red"}} ></span> 
              </p>
              <p><Popup className="sm:flex hidden" header="فوازير من حروف" content={formattedContent} /> </p>
            </div>
            <button id="b" class="reset-btn">التالي</button>
          </div>
        </div>
    </div>
    </>
  );
}

export default Guess;