function shuffleArray(arr) {
  // Start from the end of the array and swap each item with a random item
  let randomItems = [];
  let copyOfArray = arr.slice(0, 120); // make a copy of the input array
  for (let i = 0; i < 100; i++) {
    let randomIndex = Math.floor(Math.random() * copyOfArray.length);
    randomItems.push(copyOfArray[randomIndex]);
    copyOfArray.splice(randomIndex, 1); // remove selected item from copy of array
  }
  return randomItems
}

const data = [
  
    {
      question: "من هو النبي الذي كانت تسبح معه الملائكة؟",
      incorrectAnswers: [
        "سليمان عليه السلام",
        "داوود عليه السلام",
        "يونس عليه السلام",
      ],
      correctAnswer: "أيوب عليه السلام",
    },
    {
      question:
        "ماهي الآيات العشر التي تعصم من قرأها من المسيح الدجال؟",
      incorrectAnswers: [
        "أواخر سورة الحشر",
        "أواخر سورة البقرة",
        "أواخر سورة الكهف",
      ],
      correctAnswer: "أوائل سورة الكهف",
    },
    {
      question: "ما اسم ناقة الرسول صلى الله عليه وسلم؟",
      incorrectAnswers: ["الروضاء", "البيداء", "العذراء"],
      correctAnswer: "القصواء",
    },
    {
      question: "ماهي السورة التي تبدأ بالحرف المتقطع (ن)؟",
      incorrectAnswers: [" سورة البقرة", " سورة غافر", " سورة الكوثر"],
      correctAnswer: " سورة القلم",
    },
    {
      question: "من أسماء ملائكة العذاب التي ذكرت في سورة العلق؟",
      incorrectAnswers: [
        " الذاريات",
        " السابحات",
        " الزاجرات",
      ],
      correctAnswer: " الزبانية",
    },
    {
      question: "ما اسم الزوجة الثالثة بالترتيب التي تزوجها النبي صلى الله عليه وسلم؟",
      incorrectAnswers: [
        " أم سلمة",
        " سودة بنت زمعة",
        " حفصة بنت عمر",
      ],
      correctAnswer: "عائشة بنت أبي بكر الصديق",
    },
    {
      question: "كم عدد السور التي ابتدأت بالحروف المقطعة (الر)؟",
      incorrectAnswers: [" ثلاث سور", " أربعة سور", " سورتان"],
      correctAnswer: " خمس سور",
    },
  
  //
  
  {
    question: "ماهي السورة التي لا يوجد بها بسملة؟",
    incorrectAnswers: [
      " الجن",
      " الحج",
      " ق",
    ],
    correctAnswer: "التوبة",
  },
  {
    question: "ما هي أرض مهد الأديان السماوية التي أقسم الله عليها؟",
    incorrectAnswers: [
      " مصر",
      " الحجاز  ",
      "سورية",
    ],
    correctAnswer: " فلسطين",
  },
  {
    question: "كم عدد ابواب الجنة ",
    incorrectAnswers: [
      " سبعة أبواب",
      " عشرة أبواب",
      " تسعة أبواب",
    ],
    correctAnswer: " ثمانية أبواب ",
  },
  {
    question: "كم عدد سور القرآن الكريم؟",
    incorrectAnswers: [
      " 113",
      " 112",
      " 111",
    ],
    correctAnswer: "114",
  },
  {
    question: "في أي الأيام خلق سيدنا آدم عليه السلام؟",
    incorrectAnswers: [
      " يوم الأربعاء",
      " يوم الاثنين",
      " يوم الخميس",
    ],
    correctAnswer: " يوم الجمعة",
  },
  {
    question: "اسم أول صحابي قرأ القرآن جهرة؟",
    incorrectAnswers: [
      "عبد الله بن الزبير",
      "عبد الله بن عمرو بن العاص",
      " عبد الله بن عباس",
    ],
    correctAnswer: " عبد الله بن مسعود ",
  },
  {
    question: "من هي أخر زوجات النبي التي توفيت؟",
    incorrectAnswers: [
      " السيدة سودة بنت زمعة",
      " السيدة عائشة بنت أبي بكر",
      " السيدة زينب بنت جحش",
    ],
    correctAnswer: " السيدة أم سلمة",
  },
  {
    question: "سورة قصيرة من قرأها ثلاثا فكأنما قرأ كل القرآن؟",
    incorrectAnswers: [
      "سورة الفاتحة",
      "سورة الفلق",
      " سورة الناس",
    ],
    correctAnswer: "سورة الإخلاص",
  },
  {
    question: "كم مرة حج محمد رسول الله عليه الصلاة والسلام؟",
    incorrectAnswers: [
      " أربع مرات",
      " مرتين",
      "ثلاث مرات",
    ],
    correctAnswer: "مرة واحدة ",
  },
  {
    question: "ما هي غزوة الفرقان؟",
    incorrectAnswers: [
      "غزوة الطائف",
      "غزوة الخندق",
      "غزوة أحد",
    ],
    correctAnswer: "غزوة بدر",
  },
  {
    question: "ما هي السورة التي تسمى بقلب القرآن؟",
    incorrectAnswers: [
      " سورة الرحمن",
      " سورة البقرة",
      " سورة الفاتحة",
    ],
    correctAnswer: " سورة يس",
  },
  {
    question: "في أي عام تم فرض فريضة الصيام؟",
    incorrectAnswers: [
      "العام الرابع من الهجرة",
      " العام الثالث من الهجرة",
      " العام الأول من الهجرة",
    ],
    correctAnswer: " العام الثاني من الهجرة ",
  },
  {
    question: "كم كان عمر النبي محمد صلى الله عليه وسلم عندما توفيت أمه؟",
    incorrectAnswers: [
      " 4 سنوات",
      " 3 سنوات",
      " 6 سنوات",
    ],
    correctAnswer: " 5 سنوات",
  },
  {
    question: "ما هي أطول سورة في القرآن الكريم؟",
    incorrectAnswers: [
      " سورة المائدة",
      " سورة النساء",
      " سورة آل عمران",
    ],
    correctAnswer: " سورة البقرة",
  },
  {
    question: "الشهر الثالث في التقويم الهجري؟",
    incorrectAnswers: [
      " ذو القعدة",
      " جمادى الآخرة",
      " شعبان",
    ],
    correctAnswer: " ربيع الأول",
  },
  {
    question: "ما السورة التي ختمت باسم وقت من أوقات الصلاة؟",
    incorrectAnswers: [
      "سورة الفجر",
      "سورة الأعلى",
      "سورة البلد",
    ],
    correctAnswer: " سورة القدر",
  },
  {
    question: "كم عدد التكبيرات في الركعة الأولى في صلاة العيد؟",
    incorrectAnswers: [
      "خمسة",
      "تسعة",
      " ستة",
    ],
    correctAnswer: " سبعة",
  },
  {
    question: "في أي جهة يقع باب الكعبة المشرفة؟",
    incorrectAnswers: [
      " في الجهة الشمالية من الكعبة",
      " في الجهة الجنوبية من الكعبة",
      " في الجهة الغربية من الكعبة",
    ],
    correctAnswer: "في الجهة الشرقية من الكعبة",
  },
  {
    question: "كم مرة ذكرت السيدة مريم في القرآن الكريم؟",
    incorrectAnswers: [
      "42 مرة",
      "55 مرة",
      " 43 مرة",
    ],
    correctAnswer: "34 مرة",
  },
  {
    question: "قال تعالى في سورة العاديات: ( فالمغيرات صبحا (3)) ماالمقصود بالمغيرات؟",
    incorrectAnswers: [
      "الملائكة السابحين",
      " الابل",
      " الرياح الشديدة",
    ],
    correctAnswer: " الخيل",
  },
  {
    question: "ما هي أعظم آية في القرآن الكريم؟",
    incorrectAnswers: [
      " أول آية من سورة البقرة",
      " آخر آية من سورة الكهف",
      " آخر آية من سورة البقرة",
    ],
    correctAnswer: "آية الكرسي",
  },
  {
    question: "أحد هؤلاء الصحابة من العشرة المبشرين بالجنة؟",
    incorrectAnswers: [
      " الأشعث بن قيس",
      "عقيل بن أبي طالب",
      " سلمان الفارسي",
    ],
    correctAnswer: " سعد بن أبي وقاص",
  },
  {
    question: "في أي سنة هجرية كانت غزوة أحد؟",
    incorrectAnswers: [
      " السنة السادسة للهجرة",
      " السنة الخامسة للهجرة",
      " السنة الرابعة للهجرة",
    ],
    correctAnswer: " السنة الثالثة للهجرة",
  },
  {
    question: "من هو النبي الذي أرسل الى قوم ثمود؟",
    incorrectAnswers: [
      " شعيب",
      "نوح",
      " هود",
    ],
    correctAnswer: " صالح",
  },
  {
    question: "ما عدد أحزاب القرآن الكريم؟",
    incorrectAnswers: [
      "30 حزبا",
      "40 حزبا",
      "50 حزبا",
    ],
    correctAnswer: "60 حزبا",
  },
  {
    question: "قال تعالى في سورة المدثر: (والرجز فاهجر (5)) ما المقصود بالرجز؟",
    incorrectAnswers: [
      " النوم",
      " الجدال",
      " الكذب",
    ],
    correctAnswer: " الأوثان",
  },
  {
    question: "قال تعالى: (والصافات صفا * فالزاجرات زجرا) ما المقصود بالزاجرات؟",
    incorrectAnswers: [
      "السحاب",
      "الغمام",
      "الرياح",
    ],
    correctAnswer: "الملائكة",
  },
  {
    question: "من هو الصحابي الذي كانت تستحي منه ملائكة السماء؟",
    incorrectAnswers: [
      "أبو بكر الصديق",
      "عمر بن الخطاب",
      "علي بن أبي طالب",
    ],
    correctAnswer: "عثمان بن عفان",
  },
  {
    question: "ما اسم السورة التي تنتهي آياتها بحرف السين؟",
    incorrectAnswers: [
      " الضحى",
      " الصمد",
      " الفلق",
    ],
    correctAnswer: "الناس",
  },
  {
    question: "كم عدد السور التي ابتدأت بالحروف المقطعة (الم)",
    incorrectAnswers: [
      " خمس سور",
      " ثلاث سور",
      " أربعة سور",
    ],
    correctAnswer: "ستة سور",
  },
  {
    question: "هو عدد حملة العرش يوم القيامة والمذكورين في القرآن الكريم؟",
    incorrectAnswers: [
      "تسعة",
      " ستة",
      " سبعة",
    ],
    correctAnswer: "ثمانية",
  },
  {
    question: "أين ذكرت الآية الكريمة: ( وما آتاكم الرسول فخذوه وما نهاكم عنه فانتهوا)؟",
    incorrectAnswers: [
      "سورة الواقعة",
      "سورة الجمعة",
      "سورة الصف",
    ],
    correctAnswer: " سورة الحشر",
  },
  {
    question: "ما هي السورة التي يطلق عليها أم الكتاب؟",
    incorrectAnswers: [
      " سورة يس",
      " سورة البقرة",
      " سورة محمد",
    ],
    correctAnswer: " سورة الفاتحة",
  },
  {
    question: "كم شخص اشترك في قتل ناقة النبي صالح عليه السلام؟",
    incorrectAnswers: [
      "عشرة أشخاص",
      "خمسة أشخاص",
      "ثمانية أشخاص",
    ],
    correctAnswer: "تسعة أشخاص",
  },
  {
    question: "من الذي لقب الرسول الكريم بالطيب المطيب؟",
    incorrectAnswers: [
      "أنس بن مالك",
      "أسامة بن زيد",
      "زيد بن حارثة",
    ],
    correctAnswer: "عمار بن ياسر",
  },
  {
    question: "في أي سورة وردت الآية: (يمحق الله الربا ويربي الصدقات والله لا يحب كل كفار أثيم)؟",
    incorrectAnswers: [
      "هود",
      "آل عمران",
      "المائدة",
    ],
    correctAnswer: " البقرة",
  },
  {
    question: "في أي سورة وردت الآية التالية (ولبثوا في كهفهم ثلاث مئة سنين وازدادوا تسعا)؟",
    incorrectAnswers: [
      "سورة غافر",
      "سورة مريم",
      "سورة فصلت",
    ],
    correctAnswer: "سورة الكهف",
  },
  {
    question: "من هو النبي الذي دعا ربه (توفني مسلما وألحقني بالصالحين)؟",
    incorrectAnswers: [
      "ابراهيم عليه السلام",
      "صالح عليه السلام",
      "يعقوب عليه السلام",
    ],
    correctAnswer: " يوسف عليه السلام",
  },
  {
    question: "ما هي الرادفة؟",
    incorrectAnswers: [
      "انشقاق الأرض",
      " النفخة الأولى في البوق",
      "يوم القيامة",
    ],
    correctAnswer: " النفخة الثانية في البوق",
  },
  {
    question: "السورة التي ابتدأت بالحرفين المقطعين (طس)؟",
    incorrectAnswers: [
      "سورة الأعراف",
      "سورة الرعد",
      "سورة الحجر",
    ],
    correctAnswer: "سورة النمل",
  },
  {
    question: "كم عدد السور التي افتتحت بثلاثة أحرف؟",
    incorrectAnswers: [
      "14 سورة",
      "15 سورة",
      "16 سورة",
    ],
    correctAnswer: " 13 سورة",
  },
  {
    question: "كم عدد آيات سورة البقرة؟",
    incorrectAnswers: [
      "298 آية",
      "277 آية",
      "280 آية",
    ],
    correctAnswer: "286 آية",
  },
  {
    question: "من أسماء الخيل التي ذكرت في القرآن الكريم؟",
    incorrectAnswers: [
      "الذاريات",
      "النازعات",
      " الزاجرات",
    ],
    correctAnswer: " العاديات",
  },
  {
    question: "كم مرة اعتمر النبي صلى الله عليه وسلم؟",
    incorrectAnswers: [
      " سبع عمرات",
      "خمس عمرات",
      "عمرة واحدة",
    ],
    correctAnswer: " أربع عمرات",
  },
  {
    question: "ما اسم أخو يوسف عليه السلام من أمه وأبيه؟",
    incorrectAnswers: [
      "يوشع",
      "يهوذا",
      " روأبين",
    ],
    correctAnswer: "بنيامين",
  },
  {
    question: "في أي عام هجري وقعت غزوة خيبر؟",
    incorrectAnswers: [
      "9 هجري",
      "5 هجري",
      "10 هجري",
    ],
    correctAnswer: "7 هجري",
  },
  {
    question: "كم عدد الأنبياء الذين ذكروا في سورة الأنبياء؟ ",
    incorrectAnswers: [
      " 18 نبي ",
      " 19 نبي",
      "  25 نبي",
    ],
    correctAnswer: "16 نبي ",
  },
  {
    question: " ما هو الاسم الذي يطلق على الزيادة في الدين نظير الأجل أو الزيادة فيه؟",
    incorrectAnswers: [
      " ربا المال ",
      "مضاربة مشروعة ",
      " ربا الفضل ",
    ],
    correctAnswer: " ربا النسيئة",
  },
  {
    question: "من أول من ألف في أحكام القرآن؟ ",
    incorrectAnswers: [
      "  الامام حمد بن حنبل",
      " الامام المالكي ",
      " الامام أبي حنيفة ",
    ],
    correctAnswer: " الامام الشافعي ",
  },
  {
    question: " في أي سورة ذكرت قصة قابيل وهابيل؟",
    incorrectAnswers: [
      " سورة الأنعام ",
      "سورة القصص ",
      "  سورة الأعراف",
    ],
    correctAnswer: "سورة المائدة ",
  },
  {
    question: " ما اسم الزوجة الثالثة بالترتيب التي تزوجها النبي صلى الله عليه وسلم؟",
    incorrectAnswers: [
      " سودة بنت زمعة ",
      " أم سلمة ",
      " حفصة بنت عمر ",
    ],
    correctAnswer: " عائشة بنت أبي بكر الصديق ",
  },
  {
    question: " من من الخلفاء الراشدين كان الأكبر سنا عندما لقي ربه؟",
    incorrectAnswers: [
      "علي بن أبي طالب ",
      " عمر بن الخطاب",
      "أبو بكر الصديق ",
    ],
    correctAnswer: "عثمان بن عفان ",
  },
  {
    question: " من أول من لقب بأمير المؤمنين؟",
    incorrectAnswers: [
      " عثمان بن عفان ",
      "  أبو بكر الصديق",
      " علي بن أبي طالب ",
    ],
    correctAnswer: "عمر بن الخطاب ",
  },
  {
    question: " يجب اخراج الزكاة من الذهب اذا كان يبلغ النصاب الشرعي وهو؟",
    incorrectAnswers: [
      " 58 غراما",
      "78 غراما",
      "  88 غراما",
    ],
    correctAnswer: "85 غراما ",
  },
  {
    question: "ماذا لقب ابراهيم عليه السلام؟ ",
    incorrectAnswers: [
      "  اسرائيل",
      " شيخ المرسلين ",
      "  الصديق",
    ],
    correctAnswer: "الخليل ",
  },
  {
    question: " أين وردت الآية الكريمة ( أقم الصلاة لدلوك الشمس إلى غسق الليل وقرآن الفجر إن قرآن الفجر كان مشهودا)؟",
    incorrectAnswers: [
      "سورة الشمس",
      " سورة التكوير",
      " سورة النور ",
    ],
    correctAnswer: " سورة الاسراء ",
  },
  {
    question: " من هو الصحابي الذي كان جبريل يأتي على هيئته؟",
    incorrectAnswers: [
      "سعد بن معاذ ",
      "  حذيفة بن اليمان",
      "سعد بن أبي وقاص ",
    ],
    correctAnswer: "  دحية الكلبي",
  },
  {
    question: " أول زوجات النبي صلى الله عليه وسلم لحوقا به بعد وفاته؟",
    incorrectAnswers: [
      "  سودة بنت زمعة",
      "  أم سلمة",
      "  حفصة بنت عمر",
    ],
    correctAnswer: "  زينب بنت جحش",
  },
  {
    question: " كم كان عمر الرسول صلى الله عليه وسلم عندما بعث نبيا ورسولا للإسلام؟",
    incorrectAnswers: [
      "خمسون عاما ",
      "  ثلاثون عاما",
      "  عشرون عاما",
    ],
    correctAnswer: "أربعون عاما ",
  },
  {
    question: "ما هي السورة التي وردت فيها غزوة أحد وغزوة بدر؟ ",
    incorrectAnswers: [
      "سورة الأنعام ",
      " سورة المائدة ",
      "  سورة البقرة",
    ],
    correctAnswer: "  سورة آل عمران",
  },
  {
    question: " ما هو العام الهجري الذي احتفل فيه المسلمين بعيد الفطر وعيد الأضحى المبارك؟",
    incorrectAnswers: [
      " العام الرابع",
      " العام الثالث ",
      "  العام الأول",
    ],
    correctAnswer: "  العام الثاني",
  },
  {
    question: " ما هو أول الأيام التي خلقها الله تعالى؟",
    incorrectAnswers: [
      " يوم الثلاثاء ",
      "  يوم الجمعة",
      "  يوم السبت",
    ],
    correctAnswer: " يوم الأحد ",
  },
  {
    question: " ما هي السورة الوحيدة في القرآن الكريم التي لا تحتوي على حرف الميم؟",
    incorrectAnswers: [
      " سورة الفيل ",
      "  سورة النصر",
      " سورة الناس",
    ],
    correctAnswer: "سورة الكوثر ",
  },
  {
    question: " من هي آسيا؟",
    incorrectAnswers: [
      " زوجة لوط",
      " زوجة لقمان",
      "  زوجة نوح",
    ],
    correctAnswer: "زوجة فرعون",
  },
  {
    question: " ما هي أول صلاة صلاها الرسول صلى الله عليه وسلم؟",
    incorrectAnswers: [
      "  صلاة العشاء",
      "  صلاة العصر",
      " صلاة الفجر ",
    ],
    correctAnswer: "  صلاة الظهر",
  },
  {
    question: "كم مرة ذكر اسم الرسول محمد صلى الله عليه وسلم في القرآن؟ ",
    incorrectAnswers: [
      "  6 مرات",
      "  5 مرات",
      " 3 مرات",
    ],
    correctAnswer: "  4 مرات",
  },
  {
    question: " من هو النبي الذي آمن به جميع قومه؟",
    incorrectAnswers: [
      " إبراهيم عليه السلام",
      " شعيب عليه السلام",
      "نوح عليه السلام ",
    ],
    correctAnswer: "يونس عليه السلام ",
  },
  {
    question: " من هو أول صحابي حيّا الرسول صلى الله عليه وسلم بتحية الإسلام؟",
    incorrectAnswers: [
      " أبو موسى الأشعري",
      "أبو أيوب الأنصاري ",
      " أبو بكر الصديق",
    ],
    correctAnswer: "  أبو ذر الغفاري",
  },
  {
    question: " قال تعالى: (وزرابي مبثوثة) سورة الغاشية (16)، المقصود بـ(زرابي)؟",
    incorrectAnswers: [
      " الفرش",
      "  الوسائد",
      " الأسرة",
    ],
    correctAnswer: " الطنافس",
  },
  {
    question: " أقسم الله عزوجل بالتين والزيتون كناية عن الأرض التي تزرع فيها ومهبط الرسالات السماوية وهي أرض؟",
    incorrectAnswers: [
      "اليمن",
      "الحجاز",
      "مصر",
    ],
    correctAnswer: "فلسطين",
  },
  {
    question: "بدأت سورة الشعراء بعد البسملة بثلاثة حروف هي؟",
    incorrectAnswers: [
      "كهع",
      "الر",
      "الم",
    ],
    correctAnswer: "طسم",
  },
  {
    question: "من قائد المسلمين في معركة القادسية؟",
    incorrectAnswers: [
      "شرحبيل بن حسنة",
      "عمرو بن العاص",
      " خالد بن الوليد",
    ],
    correctAnswer: "سعد بن أبي وقاص",
  },
  {
    question: "ما أكثر سورة تكرر فيها اسم الرحمن؟",
    incorrectAnswers: [
      " سورة آل عمران",
      "سورة البقرة",
      "سورة الرحمن",
    ],
    correctAnswer: " سورة مريم",
  },
  {
    question: "قرية أخبر الرسول صلى الله عليه وسلم أنها (آخر قرية من قرى الإسلام خرابا) فما هي؟",
    incorrectAnswers: [
      "القدس",
      "مكة المكرمة",
      "حلب",
    ],
    correctAnswer: "المدينة المنورة",
  },
  {
    question: "من هي ثاني إمرأة دخلت الإسلام؟",
    incorrectAnswers: [
      " أم أيمن",
      " فاطمة بنت أسد",
      "سمية بنت خياط",
    ],
    correctAnswer: "أم الفضل زوجة العباس",
  },
  {
    question: "صحابي قال له النبي صلى الله عليه وسلم (أن الله مقمصمك قميصا فإن أرادك المنافقون على خلعه فلا تخلعه) فمن هو؟",
    incorrectAnswers: [
      "علي بن أبي طالب",
      " عمر بن الخطاب",
      "أبو بكر الصديق",
    ],
    correctAnswer: "عثمان بن عفان",
  },
  {
    question: "كم مرة وردت كلمة (تلاوة) في القرآن الكريم؟",
    incorrectAnswers: [
      "سبع مرات",
      "خمس مرات",
      " ثلاث مرات",
    ],
    correctAnswer: " مرة واحدة",
  },
  {
    question: "صحابي قال له الرسول صلى الله عليه وسلم: ( تمشي وحدك وتموت وحدك وتبعث وحدك) فمن هو؟",
    incorrectAnswers: [
      "أبو بصير",
      "أبو أيوب الأنصاري",
      "أبو موسى الأشعري",
    ],
    correctAnswer: "أبو ذر الغفاري",
  },
  {
    question: "قال تعالى: (ننزل الملائكة والروح فيها بإذن ربهم) سورة القدر(4) المقصود بـ(الروح) هو؟",
    incorrectAnswers: [
      "عزرائيل",
      "اسرافيل",
      "ميكائيل",
    ],
    correctAnswer: " جبريل",
  },
  {
    question: " ما هو عدد السجدات في القرآن الكريم؟",
    incorrectAnswers: [
      "17 سجدة",
      "18 سجدة",
      "12 سجدة",
    ],
    correctAnswer: "15 سجدة",
  },
  {
    question: "من الذي عقد مع رسول الله صلى الله عليه وسلم صلح الحديبية عن قريش؟",
    incorrectAnswers: [
      " أبو الحكم بن هشام",
      "الوليد بن المغيرة",
      " أبو سفيان بن حرب",
    ],
    correctAnswer: " سهيل بن عمرو",
  },
  {
    question: "كم كان عمر النبي صلى الله عليه وسلم حين وفاة زوجته خديجة بنت خويلد رضى الله عنها؟",
    incorrectAnswers: [
      " 53 عاما",
      " 48 عاما",
      " 45 عاما",
    ],
    correctAnswer: "51 عاما",
  },
  {
    question: "ما آخر سورة نزلت في مكة؟",
    incorrectAnswers: [
      " الزلزلة",
      " القارعة",
      "النصر",
    ],
    correctAnswer: " المرسلات",
  },
  {
    question: "كم ورد لفظ (الصمد) في القرآن الكريم؟",
    incorrectAnswers: [
      "ست مرات",
      " أربع مرات",
      " مرتان",
    ],
    correctAnswer: " مرة واحدة",
  },
  {
    question: "في عهد من من خلفاء المسلمين وقعت معركة اليرموك؟",
    incorrectAnswers: [
      " علي بن أبي طالب",
      " عثمان بن عفان",
      " أبو بكر الصديق",
    ],
    correctAnswer: "عمر بن الخطاب",
  },
  {
    question: "ما هو الشئ الذي كان النبي صلى الله عليه وسلم لا يرده؟",
    incorrectAnswers: [
      " الطعام",
      "الثياب",
      "الكحل",
    ],
    correctAnswer: " الطيب",
  },
  {
    question: "متى توفيت أم المؤمنين زينب بنت جحش رضى الله عنها؟",
    incorrectAnswers: [
      " سنة 30 هجرية",
      " سنة 25 هجرية",
      " سنة 15 هجرية",
    ],
    correctAnswer: " سنة 20 هجرية",
  },
  {
    question: "كم وردت كلمة (أف) في القرآن الكريم ؟",
    incorrectAnswers: [
      " سبع مرات",
      "خمس مرات",
      "مرتان ",
    ],
    correctAnswer: " ثلاث مرات",
  },
  {
    question: "القائد المسلم الذي فتح مدينة تدمر الأثرية هو؟",
    incorrectAnswers: [
      "سعد بن أبي وقاص",
      " شرحبيل بن حسنة",
      "عمرو بن العاص",
    ],
    correctAnswer: "خالد بن الوليد",
  },
  {
    question: "في أي عام فرضت زكاة الفطر؟",
    incorrectAnswers: [
      " عام 4 هجرية",
      "عام 3 هجرية",
      " عام 1 هجرية",
    ],
    correctAnswer: "عام 2 هجرية",
  },
  {
    question: "من هو أول شهيد في غزوة أحد؟",
    incorrectAnswers: [
      " أبو دجانة",
      "عبدالله بن جحش",
      " عمير بن أبي وقاص",
    ],
    correctAnswer: " عبدالله بن حرام",
  },
  {
    question: "قال تعالى: (ولا طعام إلا من غسلين) سورة الحاقة (36)، ما المقصود بـ(غسلين)؟",
    incorrectAnswers: [
      "شجرة شوكية",
      "شجرة الزقوم",
      " ماء شديد الحرارة",
    ],
    correctAnswer: "القيح والصديد",
  },
  {
    question: "كم ورد لفظ (الكعبة) في القرآن الكريم؟",
    incorrectAnswers: [
      " سبع مرات",
      "أربع مرات",
      " مرة",
    ],
    correctAnswer: "مرتان",
  },
  {
    question: "من الذي دخل النبي صلى الله عليه وسلم في جواره حين عاد من الطائف؟",
    incorrectAnswers: [
      " أمية بن الصلت",
      "ورقة بن نوفل",
      " سهيل بن عمرو",
    ],
    correctAnswer: " المطعم بن عدي",
  },
  {
    question: "كم ورد لفظ (الكهف) في القرآن الكريم؟",
    incorrectAnswers: [
      "ست مرات",
      " مرتان",
      "مرة واحدة",
    ],
    correctAnswer: "أربع مرات",
  },
  {
    question: "من أول من ركب الخيل؟",
    incorrectAnswers: [
      "سليمان بن داوود",
      "نوح عليه السلام",
      " قابيل بن أدم",
    ],
    correctAnswer: " اسماعيل عليه السلام",
  },
  {
    question: " قال تعالى: (كأنهم حمر مستنفرة(50) فرت من قسورة) ما معنى قسورة؟",
    incorrectAnswers: [
      "  نمر",
      "  ذئب",
      "  ثعلب",
    ],
    correctAnswer: " أسد",
  },
  {
    question: "من قائل هذا الدعاء: (رب بما أنعمت علي فلن أكون ظهيرا للمجرمين)؟ ",
    incorrectAnswers: [
      " سليمان عليه السلام ",
      " هود عليه السلام ",
      " عيسى عليه السلام ",
    ],
    correctAnswer: " موسى عليه السلام",
  },
  {
    question: " ما هي السورة التي ورد فيها ذكر الذباب؟",
    incorrectAnswers: [
      " المؤمنون ",
      " النساء",
      "  الأنبياء",
    ],
    correctAnswer: "  الحج",
  },
  {
    question: " من آخر من توفى من الصحابة؟",
    incorrectAnswers: [
      "  زيد بن حارثة",
      "  الزبير بن العوام",
      "  أسامة بن زيد",
    ],
    correctAnswer: " أبو الطفيل عامر بن وائلة ",
  },
  {
    question: "من هو النبي الذي كفل مريم العذراء؟ ",
    incorrectAnswers: [
      " يحيى عليه السلام ",
      "  أيوب عليه السلام",
      "  ابراهيم عليه السلام",
    ],
    correctAnswer: " زكريا عليه السلام ",
  },
  {
    question: "ما هو اسم أبو جهل؟ ",
    incorrectAnswers: [
      "  عبد العزى",
      " أمية بن خلف ",
      " عمرو بن سعد ",
    ],
    correctAnswer: "  عمرو بن هشام",
  },
  {
    question: "ما هو أول مسجد في الإسلام؟ ",
    incorrectAnswers: [
      "المسجد النبوي ",
      " المسجد الحرام ",
      " المسجد الأقصى ",
    ],
    correctAnswer: " مسجد قباء ",
  },
  {
    question: "من هو الصحابي الملقب بترجمان القرآن؟",
    incorrectAnswers: [
      " عبدالله بن مسعود ",
      "  عبدالله بن عمرو بن العاص",
      "  عبد الرحمن بن عوف",
    ],
    correctAnswer: "  عبدالله بن عباس",
  },
  {
    question: " ما هي السورة التي تقع في نصف القرآن؟",
    incorrectAnswers: [
      "  سورة يوسف",
      "  سورة الحج",
      " سورة هود ",
    ],
    correctAnswer: " سورة الكهف ",
  },
  {
    question: "من هي أم اسحاق عليه السلام؟ ",
    incorrectAnswers: [
      "  هاجر",
      " آسيا ",
      " مريم ",
    ],
    correctAnswer: "  سارة",
  },
  {
    question: "من معجزات الرسول محمد صلى الله عليه وسلم؟ ",
    incorrectAnswers: [
      "  كلامه في المهد",
      "  معجزة الناقة",
      " معجزة احياء الموتى ",
    ],
    correctAnswer: "  انشقاق القمر",
  },
  {
    question: " من هو النبي الذي ارسل الى قوم ثمود؟",
    incorrectAnswers: [
      " شعيب ",
      " يوشع بن نون ",
      "  هود",
    ],
    correctAnswer: "  صالح",
  },
  {
    question: " الصحراء الذي أقام فيها موسى مع قومه بعد أن أنجاهم الله من فرعون؟",
    incorrectAnswers: [
      " الصحراء العربية ",
      " الربع الخالي ",
      " صحراء النقب ",
    ],
    correctAnswer: "  صحراء سيناء",
  },
  {
    question: "من هم القوم الذي أرسل لهم الله الناقة؟ ",
    incorrectAnswers: [
      " قوم شعيب ",
      "  قوم عاد",
      "  قوم مدين",
    ],
    correctAnswer: " قوم ثمود ",
  },
  {
    question: "كم عدة المرأة المتوفي زوجها؟ ",
    incorrectAnswers: [
      " أربعة شهور ",
      " ثلاثة شهور ",
      "  ثلاثة قروء",
    ],
    correctAnswer: "  أربع أشهر وعشرة أيام",
  },
  {
    question: "ما اسم والدة النبي صلى الله عليه وسلم؟ ",
    incorrectAnswers: [
      " أم أيمن ",
      " فاطمة بنت أسد ",
      " بركة بنت ثعلبة ",
    ],
    correctAnswer: " آمنة بنت وهب ",
  },
  {
    question: " كم كانت مدة الدعوة سرا؟",
    incorrectAnswers: [
      " 4 سنوات ",
      " 6 سنوات ",
      "  5 سنوات",
    ],
    correctAnswer: " 3 سنوات ",
  },
  {
    question: " ما اسم طعام المأتم الذي يقدم عند الوفاة؟",
    incorrectAnswers: [
      " النقيعة ",
      " العقيقة ",
      " المأدبة ",
    ],
    correctAnswer: " الوضيمة ",
  },
  {
    question: " ما هي الصلاة التي يركع فيها المصلي أربع مرات ويسجد أربع مرات؟",
    incorrectAnswers: [
      " صلاة الاستسقاء ",
      "  صلاة الجنازة",
      " صلاة العيد ",
    ],
    correctAnswer: "  صلاة الخسوف والكسوف",
  },
  {
    question: "اسم المعركة التي انتصر فيها جيش المسلمين على المغول؟ ",
    incorrectAnswers: [
      " معركة القادسية",
      "معركة نهاوند ",
      "  معركة الفتح",
    ],
    correctAnswer: " معركة عين جالوت ",
  },
  {
    question: "من هو النبي الذي سخر له الجبال تسبح معه؟ ",
    incorrectAnswers: [
      "  سليمان عليه السلام",
      " ابراهيم عليه السلام ",
      "  اسماعيل عليه السلام",
    ],
    correctAnswer: " داوود عليه السلام ",
  },
  {
    question: "كم عدد المواضع التي اقسم الله بنفسه في القرآن الكريم؟ ",
    incorrectAnswers: [
      " 6 مواضع ",
      " 5 مواضع ",
      " 9 مواضع ",
    ],
    correctAnswer: " 7 مواضع ",
  },
  {
    question: " من هي مرضعة الرسول صلى الله عليه وسلم؟",
    incorrectAnswers: [
      " بركة بنت ثعلبة ",
      "  فاطمة بنت أسد",
      "  أم أيمن",
    ],
    correctAnswer: "  حليمة السعدية",
  },
  {
    question: "من هو الصحابي الذي اشترى بئر رومة في المدينة من اليهودي ووهبه للمسلمين؟ ",
    incorrectAnswers: [
      " أبو بكر الصديق ",
      "عمار بن ياسر ",
      " عمر بن الخطاب",
    ],
    correctAnswer: " عثمان بن عفان ",
  },
  {
    question: "كم عدد الغزوات التي قاتل فيها الرسول صلى الله عليه وسلم؟ ",
    incorrectAnswers: [
      "  8 غزوات",
      "  5 غزوات",
      " 7 غزوات ",
    ],
    correctAnswer: " 9 غزوات ",
  },
  {
    question: "كم عدد أبواب النار؟ ",
    incorrectAnswers: [
      "  6 أبواب",
      " 9 أبواب",
      "  8 أبواب",
    ],
    correctAnswer: " 7 أبواب ",
  },
  {
    question: " كم سنة دامت خلافة أبو بكر الصديق رضي الله عنه؟",
    incorrectAnswers: [
      " سنة واحدة ",
      " 3 سنوات",
      "  4 سنوات",
    ],
    correctAnswer: "سنتان ",
  },
  {
    question: "من السبع الموبقات؟",
    incorrectAnswers: [
      " العداوة ",
      " السرقة ",
      " الرياء ",
    ],
    correctAnswer: "  الشرك بالله",
  },
  {
    question: "قال رسول الله صلى الله عليه وسلم: (هذا خالي فليرني امرِ خاله) فمن هو الصحابي الذي يقصده؟ ",
    incorrectAnswers: [
      " عبدالله بن مسعود ",
      " سعد بن معاذ ",
      "  أوس بن ثابت",
    ],
    correctAnswer: "  سعد بن أبي وقاص",
  },
  {
    question: " من من الخلفاء الراشدين كان الأكبر سنا عندما لقي ربه؟",
    incorrectAnswers: [
      "  علي بن أبي طالب",
      " عمر بن الخطاب",
      " أبو بكر الصديق ",
    ],
    correctAnswer: " عثمان بن عفان ",
  },{
    question: " الشهر الخامس في التقويم الهجري؟",
    incorrectAnswers: [
      "  شوال",
      " جمادى الآخرة ",
      "  ربيع الأول",
    ],
    correctAnswer: " جمادى الأول ",
  },
  {
    question: " متى وقعت غزوة الخندق؟",
    incorrectAnswers: [
      " 7  هـ ",
      "  4 هـ ",
      " 9 هـ  ",
    ],
    correctAnswer: " 5 هـ ",
  },
  {
    question: "ما هي آخر سورة نزلت في القرآن الكريم؟",
    incorrectAnswers: [
      " الناس ",
      "  الفيل",
      "  الكافرون",
    ],
    correctAnswer: "  النصر",
  },
  {
    question: " كم دامت خلافة عثمان بن عفان رضي الله عنك؟",
    incorrectAnswers: [
      " 11 سنة ",
      " 13 سنة ",
      "  9 سنوات",
    ],
    correctAnswer: " 12 سنة ",
  },
  {
    question: " ما هي السورة القرآنية التي يبكي فيها الشيطان عند سماعها؟",
    incorrectAnswers: [
      "  سورة يونس",
      "  سورة الفاتحة",
      " سورة البقرة ",
    ],
    correctAnswer: "  سورة السجدة",
  },
  {
    question: "أين يوجد البيت المعمور؟ ",
    incorrectAnswers: [
      "  في الأرض",
      "  في الجنة",
      "  ما بين الأرض والسماء",
    ],
    correctAnswer: "  في السماء",
  },
  {
    question: "ما هي المدة التي نام فيها أهل الكهف؟ ",
    incorrectAnswers: [
      "  311 سنة",
      "  310 سنة",
      "  308 سنة",
    ],
    correctAnswer: "  309 سنة",
  },
  {
    question: " في أي سنة شرع شهر رمضان المبارك؟",
    incorrectAnswers: [
      " 5 هـ ",
      "  4 هـ",
      " 3 هـ ",
    ],
    correctAnswer: " 2 هـ ",
  },
  {
    question: " كم عدد الأشهر الحرم؟",
    incorrectAnswers: [
      "  خمسة أشهر",
      "  ثلاثة أشهر",
      "  شهران",
    ],
    correctAnswer: " أربعة أشهر ",
  },
  {
    question: " كم عدد أبناء الرسول صلى الله عليه وسلم؟",
    incorrectAnswers: [
      " ثمانية أبناء ",
      "  ستة أبناء",
      "  خمسة أبناء",
    ],
    correctAnswer: "  سبعة أبناء",
  },
  {
    question: "من أول من أسلم من الرجال؟ ",
    incorrectAnswers: [
      " علي بن أبي طالب",
      " عثمان بن عفان ",
      "  عمر بن الخطاب",
    ],
    correctAnswer: " أبو بكر الصديق ",
  },
  {
    question: " من أول من أسلم من الأنصار؟",
    incorrectAnswers: [
      " سعد بن معاذ",
      "  أبو عبيدة بن الجراح",
      " زيد بن الحارثة ",
    ],
    correctAnswer: "  جابر بن عبد الله",
  },
  {
    question: "ما هي أصغر سورة في القرآن الكريم؟ ",
    incorrectAnswers: [
      "الفلق ",
      " النبأ ",
      " البقرة",
    ],
    correctAnswer: " الكوثر ",
  },
  {
    question: "في أي سنة للهجرة توفي الرسول صلى الله عليه وسلم؟ ",
    incorrectAnswers: [
      "  14 هـ",
      " 13 هـ",
      "  12 هـ",
    ],
    correctAnswer: "  11 هـ",
  },
  {
    question: "من السبع الموبقات؟ ",
    incorrectAnswers: [
      " التلاعب بالكيل ",
      "  السرقة",
      " كنز الذهب ",
    ],
    correctAnswer: "  أكل الربا",
  },
  {
    question: "متى لقب أبو بكر بالصديق؟ ",
    incorrectAnswers: [
      "  في حادثة الإفك",
      " غزوة بدر ",
      "  غزوة الخندق",
    ],
    correctAnswer: "في حادثة الإسراء والمعراج",
  },
  {
    question: " من مؤلف كتاب الجامع الصحيح؟",
    incorrectAnswers: [
      "  الطبري",
      " الترمذي ",
      "  الإمام مسلم",
    ],
    correctAnswer: " الإمام البخاري",
  },
  {
    question: " ما اسم الزوجة الرابعة بالترتيب التي تزوجها النبي صلى الله عليه وسلم؟",
    incorrectAnswers: [
      "  جويرية بنت الحارث",
      " أم سلمة ",
      "  سودة بنت زمعة",
    ],
    correctAnswer: " حفصة بنت عمر ",
  },
  {
    question: "اسم المعركة التي انتصر فيها جيش المسلمين على المغول؟ ",
    incorrectAnswers: [
      " معركة الفتح ",
      "  معركة نهاوند",
      " معركة القادسية ",
    ],
    correctAnswer: " معركة عين جالوت ",
  },
  {
    question: " من هو الصحابي الملقب بذي النورين؟",
    incorrectAnswers: [
      "  أبو بكر الصديق",
      "  عمر بن الخطاب",
      " عمرو بن العاص ",
    ],
    correctAnswer: "  عثمان بن عفان",
  },
  {
    question: " قال تعالى في سورة الذاريات (فالجاريات يسرا) المقصود بالجاريات في الآية هو؟ ",
    incorrectAnswers: [
      " الغمام ",
      " الملائكة ",
      "الرياح ",
    ],
    correctAnswer: "  السفن",
  },
  {
    question: "من هي المرأة التي نذرت ما في بطنها محررا؟ ",
    incorrectAnswers: [
      "  آسيا بنت مزاحم",
      " مريم العذراء ",
      "  أم موسى عليه السلام",
    ],
    correctAnswer: " امرأة عمران ",
  },
  {
    question: " من هو النبي الذي لقب بالصديق؟",
    incorrectAnswers: [
      " يونس عليه السلام ",
      "  يعقوب عليه السلام",
      " أيوب عليه السلام ",
    ],
    correctAnswer: "  يوسف عليه السلام",
  },
  {
    question: "من هو خطيب الرسول صلى الله عليه وسلم؟ ",
    incorrectAnswers: [
      "  مصعب بن عمير",
      " كعب بن مالك ",
      " عمرو بن العاص ",
    ],
    correctAnswer: "  ثابت بن قيس",
  },
  {
    question: "من هو النبي الذي قبضت روحه في السماء؟ ",
    incorrectAnswers: [
      " عيسى عليه السلام ",
      "  موسى عليه السلام",
      "  آدم عليه السلام",
    ],
    correctAnswer: " ادريس عليه السلام ",
  },
  {
    question: "قال تعالى في سورة القلم (فأصبحت كالصريم (20)) ما المقصود بالصريم؟  ",
    incorrectAnswers: [
      " خضراء ",
      "  كاشراقة النهار",
      "  كنوز الشمس",
    ],
    correctAnswer: "  الليل الأسود",
  },
  {
    question: " إلى أي قوم أرسل نبي الله شعيب عليه السلام؟",
    incorrectAnswers: [
      "  قوم عاد",
      "  أهل نينوى",
      "  قوم ثمود",
    ],
    correctAnswer: "  قوم مدين",
  },
  {
    question: "في أي سورة وردت الآية (يا أيها الناس اتقوا ربكم إن زلزلة الساعة شيء عظيم)؟ ",
    incorrectAnswers: [
      "  سورة البقرة",
      "  سورة الغاشية",
      "  سورة الأعراف",
    ],
    correctAnswer: " سورة الحج ",
  },
  {
    question: " ما هي السورة التي تقع البسملة في وسطها؟  ",
    incorrectAnswers: [
      "  سورة القصص",
      "  سورة الشعراء",
      " سورة الحج ",
    ],
    correctAnswer: " سورة النمل",
  },
  {
    question: " كم مرة ورد لفظ ( بسم ) في القرآن الكريم؟",
    incorrectAnswers: [
      " 5 مرات ",
      " 3 مرات ",
      " 8 مرات ",
    ],
    correctAnswer: " 7 مرات ",
  },
  {
    question: "ما معنى ..القطر  .. في قوله تعالى (وأسلنا له عين القطر)؟ ",
    incorrectAnswers: [
      "الأحجار الكريمة ",
      "  الفظة",
      " الذهب",
    ],
    correctAnswer: " النحاس",
  },
  {
    question: " ما هو اللون الذي كانت تكسى به الكعبة قبل اللون الأسود؟",
    incorrectAnswers: [
      " الأبيض ",
      " الأزرق",
      "  الذهبي",
    ],
    correctAnswer: " الأخضر",
  },
  {
    question: "المسجد الأقصى هو ثاني مسجد وضع في الأرض بعد المسجد الحرام بمدة قدرها؟ ",
    incorrectAnswers: [
      "  80 سنة",
      " 100 سنة ",
      "  1000 سنة",
    ],
    correctAnswer: "  40 سنة",
  },
  
  
];

const shuffledArray = shuffleArray(data);


export default shuffledArray;