import { useContext } from "react";
import Question from "./Question";
import { QuizContext } from "../contexts/quiz";
import Popup from "../../Popup/Popup";


const Quiz = () => {
  const [quizState, dispatch] = useContext(QuizContext);
  const correctAnswersCount = localStorage.getItem("correctAnswersCount");
  const qIndex = localStorage.getItem("qIndex");
  const score = correctAnswersCount * 3 + (qIndex - correctAnswersCount);


  return (
    <div className="quiz">
      {!quizState.showResults && (
        <div className="div">
          <div style={{marginLeft:"20px"}} id="app">
            <h1 style={{color:"white"}}> {qIndex} : السؤال رقم</h1>
            <br />
            {/* <button onClick={onClickReset}>Reset</button> */}
          </div>
          <Question />
          {quizState.currentAnswer && (
            <button
            
              onClick={() => {
                setTimeout(() => {
                  dispatch({ type: "NEXT_QUESTION" });
                }, 500)
              }}
              id="nextButton"
              className="next-button"
            >
            السؤال التالي
            </button>
          )}
        </div>
      )}
      <Popup className="sm:flex hidden" header="GuessWord" content="Guess Word Game" />
      <br />
      <br /><br /><br />
    </div>
  );
};

export default Quiz;