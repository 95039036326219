import './SocialIcons.css'

const SocialIcons = (props) => {
  return (
    <>
      <ul className='ul'>
        <li><a href={props.href}>{props.icon}</a></li>
      </ul>
    </>
  )
}

export default SocialIcons
