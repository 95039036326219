import './ImageSlider.css'

const ImageSlider = () => {
  return (
    <>
      <div class="containerslider">
        <h1 className='text-[32px] text-[#FFFFFF] flex justify-center'>تابعني على اليوتيوب</h1>
        <br />
        
        <input type="radio" name="slider" id="slide-1-trigger" className="trigger" checked />
        <label className="btn" htmlFor="slide-1-trigger"></label>
        <input type="radio" name="slider" id="slide-2-trigger" className="trigger" />
        <label className="btn" htmlFor="slide-2-trigger"></label>
        <input type="radio" name="slider" id="slide-3-trigger" className="trigger" />
        <label className="btn" htmlFor="slide-3-trigger"></label>
        <input type="radio" name="slider" id="slide-4-trigger" className="trigger" />
        <label className="btn" htmlFor="slide-4-trigger"></label>
        <div className="slide-wrapper">
            <div id="slide-role">
                <div>
                  </div>
              
                <div className="slide slide-1"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/ttKgfJAqrsM" title="Uygulama ((InfoMate AI)) React teknolojisini kullanan ilk eksiksiz proje" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></div>
                <div className="slide slide-2"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/Sjh4KERfeOQ" title="تحدث مع الذكاء الاصطناعي من موقعنا ( gpt chat )" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></div>
                <div className="slide slide-3"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/N7Odud5bqcA" title="التطبيق الثاني في المشروع الاول  InfoMate AI  ((  اختبارات اسلاميه  ))" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></div>
                <div className="slide slide-4"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/3pmCbd2f33I" title="تطبيق  (( InfoMate AI  )) المشروع الكامل الاول باستخدام  تقنية الرياكت" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></div>
            </div>
        </div>
      </div>
    </>
  );
};

export default ImageSlider;
