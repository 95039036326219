import React, { useEffect, useState } from "react";
import SideHeader from "../../Components/SideHeader/SideHeader";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import axios from "axios";
import "./Sonuc.css";
import Popup from "../../Components/Popup/Popup";

const Sonuc = (props) => {
  const correctAnswersCount = localStorage.getItem("correctAnswersCount");
  const qIndex = localStorage.getItem("qIndex");
  const [name, setname] = useState("");
  const [age, setage] = useState(null);
  const [country, setcountry] = useState("");
  const score = correctAnswersCount * 3 + (qIndex - correctAnswersCount);
  const date = new Date();
  const dateNow = `${date.getFullYear()} - ${date.getMonth()} - ${date.getDay()}`;

  const cliced = (e) => {
    e.preventDefault();
    alert("nice");

    if (
      name === "" ||
      name === null ||
      age === null ||
      country === "" ||
      country === null
    ) {
      alert("object");
    } else {
      const url = "https://mahmud.tech/connection.php";
      let fData = new FormData();
      fData.append("Name", name);
      fData.append("Age", age);

      fData.append("Country", country);
      console.log(country);
      fData.append("Score", score);
      fData.append("Date", dateNow);

      axios
        .post(url, fData)
        .then((response) => {
          console.log(response.data);
          window.location.reload()
        })
        .catch((error) => alert(error));
    }
  };
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSucces, onError);
    }
  }, []);

  function onSucces(position) {
    // console.log(position);
    let { latitude, longitude } = position.coords;
    fetch(
      `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=975f799b6288420f9e7058b4749a2623`
    )
      .then((res) => res.json())
      .then((result) => {
        let country = result.results[0].components.country;
        console.log(country);
        setcountry(country);
        // console.log(allData);
      });
  }

  function onError(error) {
    // console.log(object);
    if (error.code == 1) {
      document.getElementById("myBtn").innerHTML = "You denied the Request";
    } else if (error.code == 2) {
      document.getElementById("myBtn").innerHTML = " ";
    } else {
      document.getElementById("myBtn").innerHTML = "something errer";
    }
  }

  useEffect(() => {
    axios
      .get("https://mahmud.tech/connection.php")
      .then(function (response) {
        // Sort objects based on score in descending order
        const sortedObjects = response.data.sort((a, b) => b.Score - a.Score);

        // Get the top 5 objects
        const topFiveObjects = sortedObjects.slice(0, 5);

        for (const score of topFiveObjects) {
          const content = `
        <tr>
              <td class="border border-slate-700 ...">${score.Name}</td>
              <td class="border border-slate-700 ...">${score.Age}</td>
              <td class="border border-slate-700 ...">${score.Country}</td>
              <td class="border border-slate-700 ...">${score.Date}</td>
              <td class="border border-slate-700 ...">${score.Score}</td>
            </tr>
        `;

          document.getElementById("scores").innerHTML += content;
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);
  const content = 
  "طريقه حساب النتيجة.لكل جواب صحيح ثلاث نقاط .وللاجابه خاطئه نقطه واحدة .كما يمكنك عند انتهاء المسابقه .أن تثبت اسمك دون الحاجه الى الاشتراك وتسجيل.فقط اكتب اسمك وكم عمرك وسنقوم بطباعة نتيجتك بالاضافة لتاريخ النتيجة وموقعك الحالي. كما ثبتنا اعلى 10 نتائج في المسابقة";
  
  const sentences = content.split('.');

  const formattedContent = sentences.map((sentence, index) => (
    <p key={index}>{sentence.trim()}</p>
  ));

  return (
    <>
      <SideHeader />
      <Header element={<Popup header="تطبيق مواقيت الصلاة" content={formattedContent}  />} name="إختبارات إسلامية" />
      <div className="padding padding2">
        <div className="resultss">
          <div className="congratulations">إنتهى الإختبار </div>
          <div className="results-info">
            <div>النتيجة النهائية </div>

            <div>
              عدد الإجابات الصحيحة ={" "}
              <span className="text-green-500">
                {correctAnswersCount === undefined ? 0 : correctAnswersCount}
              </span>{" "}
              <br /> عدد الإجابات الخاطئة ={" "}
              <span className="text-red-500">
                {qIndex - correctAnswersCount}{" "}
              </span>
              <br /> النتيجة : ={" "}
              <span className="text-yellow-500">{score} </span>
            </div>
          </div>
          <div
            onClick={() => {
              window.location.href = "/fuaziir";
              localStorage.setItem("correctAnswersCount", 0);
              localStorage.setItem("qIndex", 0);
            }}
            className="next-button"
          >
            حاول مجدداً
          </div>
        </div>
        <br />
        <br />
        {/* login to score */}
        <h1 style={{ textAlign: "center" }}>احفظ نتيجتك بين الاوائل</h1>
        <form className="formL w-[100%]">
          <input
            value={name}
            onChange={(e) => {
              setname(e.target.value);
            }}
            className="input"
            name="text"
            placeholder="ادخل اسمك "
          />
          <input
            className="input"
            onChange={(e) => {
              setage(e.target.value);
            }}
            type="number"
            placeholder="ادخل عمرك "
          />
          <button className="button-sonuc" onClick={cliced}>احفظ</button>
        </form>

        <br />
        <br />

        {/* leaderBoarad */}
        <table className="border-separate border border-slate-500 w-[100%]">
          <thead>
            <tr>
              <th className="border bg-blue-300 border-slate-600 ...">اسمك</th>
              <th className="border bg-blue-300  border-slate-600 ...">عمرك</th>
              <th className="border bg-blue-300 border-slate-600 ...">موقعك</th>
              <th className="border bg-blue-300 border-slate-600 ...">التاريخ</th>
              <th className="border bg-blue-300 border-slate-600 ...">نتيجتك</th>
            </tr>
          </thead>
          <tbody id="scores"></tbody>
        
        </table>
        <Popup className="sm:flex hidden" header="مسابقات اسلامية" content={formattedContent}  />
        <Footer />
      </div>
    </>
  );
};

export default Sonuc;
