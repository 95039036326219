import Header from "../../Components/Header/Header";
import SideHeader from "../../Components/SideHeader/SideHeader";
import './Azan.css'
import axios from 'axios'
import moment from 'moment-hijri';
import Buttons from "../../Components/Button/Button";
import React, { useEffect,useState } from 'react'
import Footer from '../../Components/Footer/Footer';
import { Helmet } from 'react-helmet-async';
import moments from 'moment'
import Clock from '../../Components/Clock/Clock';
import Popup from "../../Components/Popup/Popup";

const Azan = () => {
  const [disabled, setDisabled] = useState(false);
  const [countryL, setcountryL] = useState("");
  const [cityL, setcityL] = useState("");

  function getTimes(country,city) {
    axios.get(`http://api.aladhan.com/v1/timingsByCity?city=${city}&country=${country}&method=3`).then(function (response) {
      const respons = response.data.data
      console.log(respons)
      const time = new Date()

      // remading time
      var hours = time.getHours()


      document.getElementById("sabah").innerHTML = respons.timings.Fajr
      document.getElementById("shruk").innerHTML = respons.timings.Sunrise
      document.getElementById("dhuhr").innerHTML = respons.timings.Dhuhr
      document.getElementById("asr").innerHTML = respons.timings.Asr
      document.getElementById("magrip").innerHTML = respons.timings.Maghrib
      document.getElementById("isha").innerHTML = respons.timings.Isha

      // date code
      document.getElementById("date").innerHTML = `${respons.date.gregorian.date}`

      // city code
      document.getElementsByClassName("city")[0].innerHTML = `${respons.meta.timezone}`

      // document.getElementById("nowTime").innerHTML = `${hours} : ${formatTimePartString(time.getMinutes())}`
    }).catch(function (error) {
      console.error(error);
    });

    function getSalavat(id, salaName) {
      document.getElementById(id).innerHTML = `${salaName}`;
    }
  }

  useEffect(() => {
    if (navigator.geolocation) { 
      navigator.geolocation.getCurrentPosition(onSucces,onError)
    }
  }, []);

  function onSucces(position) {
    // console.log(position);
    let {latitude,longitude} = position.coords
    console.log(latitude,longitude) 
    fetch(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=975f799b6288420f9e7058b4749a2623`)
    .then(res => res.json())
    .then(result => {
      let country = result.results[0].components.country
      let city = result.results[0].components.state
      setcityL(city)
      setcountryL(country)
      getTimes(country,city)
      // console.log(allData);
    });
  }

  function onError(error) {
    // console.log(object);
    if (error.code == 1) {
      document.getElementById("myBtn").innerHTML = "You denied the Request"
    }else if(error.code == 2){
      document.getElementById("myBtn").innerHTML = ""
    }else{
      document.getElementById("myBtn").innerHTML = "something errer"
    }
  }
  
  function formatTimePartString(source) {
      var res = "";
      if (source < 10) {
          res = "0" + source;
      } else {
          res = source;
      }
      return res;
  }

  const cliced = () => {
    const sound = '/sounds/3.mp3'
    setDisabled(true);
    const playSound = new Audio(sound)
    playSound.play()
  }

  useEffect(() => {
    let timer;
    if (disabled) {
      timer = setTimeout(() => {
        setDisabled(false);
      }, 240000); // 4 minutes in milliseconds
    }
    return () => clearTimeout(timer);
  }, [disabled]);

// console.log(document.getElementById("myBtn") ) 

  const hijriDate = moment().format('iYYYY/iM/iD');

  const [nextPrayerTime, setNextPrayerTime] = useState('');
  const [timeRemaining, setTimeRemaining] = useState('');

  setTimeout(() => {
    const fetchPrayerTimes = async () => {
      try {
        console.log(cityL,countryL)
        const response = await axios.get('https://api.aladhan.com/v1/timingsByCity', {
          params: {
            city: `${cityL}`,
            country: `${countryL}`,
            method: 3, // Adjust the method based on your preference
          },
        });

        const prayerTimes = response.data.data.timings;

        const filteredPrayerTimes = {
          Fajr: prayerTimes.Fajr,
          Sunrise: prayerTimes.Sunrise,
          Dhuhr: prayerTimes.Dhuhr,
          Asr: prayerTimes.Asr,
          Maghrib: prayerTimes.Maghrib,
          Isha: prayerTimes.Isha,
        };

        const calculateTimeRemaining = () => {
          const currentDate = new Date();
          const currentTime = currentDate.getTime();
          let nextPrayer = '';
          let nextPrayerTimeRemaining = '';
          for (const prayer in filteredPrayerTimes) {
            const prayerTime = new Date(`${currentDate.toDateString()} ${filteredPrayerTimes[prayer]}`);
            const prayerTimestamp = prayerTime.getTime();
        
            if (prayerTimestamp > currentTime) {
              nextPrayer = prayer;
              if (nextPrayer == "Fajr"){
                document.querySelector(".isha").style.background = "radial-gradient(circle, rgba(232, 89, 75, 0.514) 51%, rgba(232, 89, 75, 0.043) 95%)"
              }else if (nextPrayer == "Sunrise"){
                document.querySelector(".fjr").style.background = "radial-gradient(circle, rgba(232, 89, 75, 0.514) 51%, rgba(232, 89, 75, 0.043) 95%)"
              }else if (nextPrayer == "Dhuhr"){
                document.querySelector(".shruq").style.background = "radial-gradient(circle, rgba(232, 89, 75, 0.514) 51%, rgba(232, 89, 75, 0.043) 95%)"
              }else if (nextPrayer == "Asr"){
                document.querySelector(".dhr").style.background = "radial-gradient(circle, rgba(232, 89, 75, 0.514) 51%, rgba(232, 89, 75, 0.043) 95%)"
              }else if (nextPrayer == "Maghrib"){
                document.querySelector(".asr").style.background = "radial-gradient(circle, rgba(232, 89, 75, 0.514) 51%, rgba(232, 89, 75, 0.043) 95%)"
              }else if (nextPrayer == "Isha"){
                document.querySelector(".magriph").style.background = "radial-gradient(circle, rgba(232, 89, 75, 0.514) 51%, rgba(232, 89, 75, 0.043) 95%)"
              }
              const remainingTime = prayerTimestamp - currentTime;
              const hours = String(Math.floor(remainingTime / (1000 * 60 * 60))).padStart(2, '0');
              const minutes = String(Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
              const seconds = String(Math.floor((remainingTime % (1000 * 60)) / 1000)).padStart(2, '0');
              nextPrayerTimeRemaining = `${hours}:${minutes}:${seconds}`;
              break;
            }
          }
        
          setNextPrayerTime(nextPrayer);
          setTimeRemaining(nextPrayerTimeRemaining);
        };

        calculateTimeRemaining();

        // Update the time remaining every second
        const interval = setInterval(calculateTimeRemaining, 1000);

        // Clean up the interval when the component is unmounted
        return () => clearInterval(interval);
      } catch (error) {
        console.error('Error fetching prayer times:', error);
      }
    };

    fetchPrayerTimes();
  }, 1000)
  
  const content = 
  " نقدم لكم تطبيق أوقات الصلاة . يستطيع هذا التطبيق اكتشاف موقعك الحالي بشكل تلقائي .إبق على استعداد مع عرض الوقت المتبقي للصلاة التالية بشكل ملائم.لست مضطرا الان لتحميل عده تطبيقات لمعرفه وقت الصلاه وحاله الطقس وسعر الصرف فلقد جمعناها لك جميعا في مكان وتطبيق واحد .لا تنسى بامكانك ان تدعمنا بشكل مجاني .من خلال الاشتراك بقنواتنا على شبكات التواصل";
  
  const sentences = content.split('.');

  const formattedContent = sentences.map((sentence, index) => (
    <p key={index}>{sentence.trim()}</p>
  ));

  


  
  return (
    <div>
      <Helmet>
        <title>Mahmud.tech - Azan</title>
        <meta name="description" content="مواقيت الصلاة ، صلاة الفجر ، صلاة الظهر ، صلاة العصر ، صلاة المغرب ، صلاة العشاء ، صلاة الفجر" />
      </Helmet>
      <Header element={<Popup header="تطبيق مواقيت الصلاة" content={formattedContent}  />}  name="مواقيت الصلاة"/>
      <SideHeader />
      <div className="containerA azanC">
        <div className="bars statusBar">
          <div id="myBtn" onClick={cliced} className="btnAdress">
            <button className="button-name" disabled={disabled}> إستمع للآذان </button>
          </div>
          <div className="status">
            <h4 style={{color:"#141415"}} className="city">اسم مدينتك</h4>
          </div>
        </div>
        {/* second bar */}
        <div className="bars timeBar">
          <div className="currentTime allContainer">
            <h5>الساعة الآن</h5>
            <h5><Clock /></h5>
          </div>
          <div className="nextSalaTime allContainer">
            <h5>باقي للاذان</h5>
            <h5 id="timeRemading">
              {timeRemaining}
            </h5>
          </div>
        </div>
        {/* third bar */}
        <div className="bars salatBar">
          <div className="father flex-row sm:flex-row-reverse">
            <div className="fjr allContainer">
              <h5> صلاة الفجر</h5>
              <h5 id="sabah">00:00</h5>
            </div>
            <div className="shruq allContainer">
              <h5>شروق الشمس </h5>
              <h5 id="shruk">00:00</h5>
            </div>
            <div className="dhr allContainer">
              <h5>صلاة الظهر</h5>
              <h5 id="dhuhr">00:00</h5>
            </div>
          </div>
          <div className="father flex-row sm:flex-row-reverse">
            <div className="asr allContainer">
              <h5>صلاة العصر</h5>
              <h5 id="asr">00:00</h5>
            </div>
            <div className="magriph allContainer">
              <h5>صلاة المغرب</h5>
              <h5 id="magrip">00:00</h5>
            </div>
            <div className="isha allContainer">
              <h5>صلاة العشاء</h5>
              <h5 id="isha">00:00</h5>
            </div>
          </div>
        </div>
        {/* fourth bar */}
        <div className="bars timeBar">
          <div className="currentTime allContainer">
            <h5 id="date">2022/5/10</h5>
          </div>
          <div className="nextSalaTime allContainer">
            <h5 id="nextPrair">{hijriDate} </h5>
          </div>
        </div>
      </div>
      <Popup className="sm:flex hidden" header="تطبيق مواقيت الصلاة" content={formattedContent}  />
      <br />
      <br />
      <Footer />
    </div>
  )
}

export default Azan
