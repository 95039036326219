import { useEffect } from "react";
import Header from "../../Components/Header/Header";
import SideHeader from "../../Components/SideHeader/SideHeader";
import "./Weather.css";
import axios from "axios";
import { format } from "date-fns";
import Footer from "../../Components/Footer/Footer";
import { Helmet } from 'react-helmet-async';
import Clock from "../../Components/Clock/Clock";
import Popup from "../../Components/Popup/Popup";

const Weather = () => {
  const apiKey = "53b1c6f80d771212d428e5bc101e07d1";
  const today = new Date();
  const formattedDate = format(today, "MMMM d"); // The format string 'MMMM d' outputs the month name and day

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSucces, onError);
    } else {
    }
  }, []);

  function onSucces(position) {
    let { latitude, longitude } = position.coords;
    axios
      .get(
        `https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=metric`
      )
      .then(function (response) {
        for (
          let index = 8;
          index < response.data.list.length;
          index = index + 8
        ) {
          let cel = response.data.list[index].weather[0].main;
          
          const date = new Date(response.data.list[index].dt_txt); // convert to Date object
          const formattedDate = date.toLocaleDateString("ar", {
            weekday: "long",
          });
          const content = `<div class="day">
                            <h6>${formattedDate}</h6>
                            <img height="140px" width="140px" src="https://openweathermap.org/img/wn/${
                                        response.data.list[index].weather[0].icon
                                      }@4x.png">
                            <h6>  ${
                              function() {
                                const weather = response.data.list[index].weather[0].main;
                                switch (weather) {
                                  case 'Clouds':
                                    return 'سحب';
                                  case 'Clear':
                                    return 'مشمس';
                                  case 'Rain':
                                    return 'مطر';
                                  case 'Drizzle':
                                    return 'رذاذ';
                                  case 'Thunderstorm':
                                    return 'عواصف رعدية';
                                  case 'Snow':
                                    return 'ثلوج';
                                  case 'Mist':
                                    return 'ضباب';
                                  case 'Atmosphere':
                                    return 'دخان';
                                  case 'Haze':
                                    return 'غبار';
                                  case 'Dust':
                                    return 'غبار';
                                  case 'Fog':
                                    return 'ضباب';
                                  case 'Sand':
                                    return 'رمال';
                                  case 'Ash':
                                    return 'رماد';
                                  case 'Squall':
                                    return 'زوابع';
                                  case 'Tornado':
                                    return 'إعصار';
                                  default:
                                    return weather; // Return the original English weather name if no translation is available
                                }
                              }()
                            }</h6>
                            <div class="flex gap-1">
                              <h6>${Math.floor(
                                response.data.list[index].main.temp_max
                              )}</h6> 
                               /
                              <h6>${Math.floor(
                                response.data.list[index + 4].main.temp
                              )} &deg;C</h6>
                            </div>
                          </div>`
          document.getElementById("forecast5").innerHTML += content;
        }

        // ! second Child
        const secondChildContent = `
                                  <div class="todayWeather">
                                  <h4> ${formattedDate} - اليوم</h4>
                                    <div class="place">
                                      ${response.data.city.name}
                                    </div>
                                    <div class="inf">
                                      <img height="140px" width="140px" src="https://openweathermap.org/img/wn/${
                                        response.data.list[0].weather[0].icon
                                      }@4x.png">
                                      <div class="flex gap-[50px]">
                                        <h4>${
                                            function() {
                                              console.log(response.data.list[0].weather[0].main)
                                              const weather = response.data.list[0].weather[0].main;
                                              switch (weather) {
                                                case 'Clouds':
                                                  return 'سحب';
                                                case 'Clear':
                                                  return 'مشمس';
                                                case 'Rain':
                                                  return 'مطر';
                                                case 'Drizzle':
                                                  return 'رذاذ';
                                                case 'Thunderstorm':
                                                  return 'عواصف رعدية';
                                                case 'Snow':
                                                  return 'ثلوج';
                                                case 'Mist':
                                                  return 'ضباب';
                                                case 'Atmosphere':
                                                  return 'دخان';
                                                case 'Haze':
                                                  return 'غبار';
                                                case 'Dust':
                                                  return 'غبار';
                                                case 'Fog':
                                                  return 'ضباب';
                                                case 'Sand':
                                                  return 'رمال';
                                                case 'Ash':
                                                  return 'رماد';
                                                case 'Squall':
                                                  return 'زوابع';
                                                case 'Tornado':
                                                  return 'إعصار';
                                                default:
                                                  return weather; // Return the original English weather name if no translation is available
                                              }
                                            }()
                                        }</h4>
                                        <h4>${Math.floor(
                                          response.data.list[0].main.temp
                                        )}&deg;C</h4>
                                      </div>
                                      
                                    </div>
                                    <div class="wind">
                                      <div class="windS">
                                        <h4>${
                                          response.data.list[0].wind.speed
                                        } km/h</h4>
                                        <hr />
                                        <h4>الرياح</h4>
                                      </div>
                                      
                                    </div>
                                  </div>
                              `;
        document.getElementById("secondChild").innerHTML = secondChildContent;
        document.getElementById("secondChild").style.background =
            "radial-gradient(circle, rgba(232, 89, 75, 0.514) 51%, rgba(232, 89, 75, 0.043) 95%)"

        // ! 1 day 3 hour
        for (let index = 0; index < 6; index++) {
          let cel = response.data.list[index].weather[0].main;
          let time = response.data.list[index].dt_txt;
          let spiltTime = time.split(" ");
          let theTime = spiltTime[1];
          const [hours, minutes] = theTime.split(":"); // split time string into hours, minutes, and seconds
          const formattedTime = `${hours}:${minutes}`;
          const content = ` <div class="day3Hour">
                            <h6>${formattedTime}</h6>
                            <img height="90px" width="90px" src="https://openweathermap.org/img/wn/${
                              response.data.list[index].weather[0].icon
                            }@4x.png">
                            <h6>  ${
                              function() {
                                const weather = response.data.list[index].weather[0].main;
                                switch (weather) {
                                  case 'Clouds':
                                    return 'سحب';
                                  case 'Clear':
                                    return 'مشمس';
                                  case 'Rain':
                                    return 'مطر';
                                  case 'Drizzle':
                                    return 'رذاذ';
                                  case 'Thunderstorm':
                                    return 'عواصف رعدية';
                                  case 'Snow':
                                    return 'ثلوج';
                                  case 'Mist':
                                    return 'ضباب';
                                  case 'Atmosphere':
                                    return 'دخان';
                                  case 'Haze':
                                    return 'غبار';
                                  case 'Dust':
                                    return 'غبار';
                                  case 'Fog':
                                    return 'ضباب';
                                  case 'Sand':
                                    return 'رمال';
                                  case 'Ash':
                                    return 'رماد';
                                  case 'Squall':
                                    return 'زوابع';
                                  case 'Tornado':
                                    return 'إعصار';
                                  default:
                                    return weather; // Return the original English weather name if no translation is available
                                }
                              }()
                            }</h6>
                            <h6>${Math.floor(
                              response.data.list[index].main.temp
                            )}&deg;C</h6>
                          </div>`;
          document.getElementById("3hour").innerHTML += content;
          // document.getElementsByClassName("day3Hour")[0].style.background =
          //   "radial-gradient(circle, rgba(232, 89, 75, 0.514) 51%, rgba(232, 89, 75, 0.043) 95%)";
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }
  const content = 
  "تستطيع الان معرفه حال الطقس في مدينتك ولمده خمسه ايام وعلى مدار الساعه. مع معرفه سرعه الرياح ودرجتك الامطار .ولست مضطرا بعد الان لتحميل هذا التطبيق بشكل منفصل .فلقد جمعناه ضمن عدة تطبيقات مهمة قمنا بتصميمها بعناية.لا تنسى بامكانك ان تدعمونا بشكل مجاني من خلال الاشتراك بقنواتنا على شبكات التواصل";
    
    const sentences = content.split('.');
  
    const formattedContent = sentences.map((sentence, index) => (
      <p key={index}>{sentence.trim()}</p>
    ));
  
  
  function onError(error) {
    if (error.code === 1) {
      document.getElementById("myBtn").innerHTML = "You denied the Request";
    } else if (error.code === 2) {
      document.getElementById("myBtn").innerHTML =
        "Your Location is not avabile";
    } else {
      document.getElementById("myBtn").innerHTML = "something errer";
    }
  }
  const hour = today.getHours()
  const minute = today.getMinutes();

  return (
    <>
      <Helmet>
        <title>Mahmud.tech - Weather</title>
        <meta name="description" content="الطقس حسب الموقع ، طقس ممطر ، طقس ربيعي ، عواصف رعدية ، أمطار غزيرة" />
      </Helmet>
      <Header element={<Popup header="تطبيق مواقيت الصلاة" content={formattedContent}  />} name="حالة الطقس" />
      <SideHeader />
      <div className="weatherContainer">
      <div id="secondChild" className="secondChild"></div>
        <div className="firstChild">
          <br />
          <br />
          <div className="text flex justify-between flex-row hidden sm:flex">
            <div >
              
              <div className="date">
                <p className="text-[26px] text-[#E2E2E2]">{formattedDate}</p>
              </div>
            </div>
            <div className="time">
                <h1 className="text-[26px] text-[#E2E2E2] "><Clock /></h1>
              </div>
            <div className="morningText">
              <h1 className="text-[26px] hidden sm:flex text-[#E2E2E2]">طاب يومك</h1>
            </div>
          </div>
          <br />
          <div id="forecast5" className="forecast5">
            {/* <div className="day">
              <h4>icon</h4>
              <p className="weatherType1">Sun</p>
              <p className="weatherType1">28C</p>
            </div> */}
          </div>
        </div>
      </div>
      <div className="secoondContainer">
        <div id="3hour" className="secoondContainerChild"></div>
      </div>
      <Popup className="sm:flex hidden" header="حالة الطقس
      
      " content={formattedContent} />
      <Footer />
      <br className="sm:hidden flex"/>
      <br className="sm:hidden flex"/>
      <br className="sm:hidden flex" />
      <br className="sm:hidden flex" />
    </>
  );
};

export default Weather;
