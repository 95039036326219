import './Footer.css'

const Footer = () => {
  return (
    <div className="sm:block hidden ">
      <div className="pagefooter">
              <div className="pagefooter-copyright">
                <p>Copyright © 2023 Mahmut.tech . All rights reserved</p>
              </div>
      </div>
    </div>
  )
}

export default Footer
