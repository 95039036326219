import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";

import { styles } from "../../styles";
import { EarthCanvas } from "./canvas";
import { SectionWrapper } from "../../hoc";
import { slideIn } from "../../utils/motion";

const Contact = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        "service_ju23eqd",
        "template_w71gstb",
        {
          from_name: form.name,
          to_name: "Memo",
          from_email: form.email,
          to_email: "vitomix655@hrisland.com",
          message: form.message,
        },
        "pML1LQtPWXpStXYTH"
      )
      .then(
        () => {
          setLoading(false);
          alert("Thank you. I will get back to you as soon as possible.");

          setForm({
            name: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          setLoading(false);
          console.error(error);

          alert("Ahh, something went wrong. Please try again.");
        }
      );
  };

  return (
    <div dir="rtl"
    style={{background: "linear-gradient(to right, #3630857c, #498196cc, #7a95a3)",boxShadow: "4px 4px 8px #282626, -4px -4px 15px #fdfdfd00",  border: "2.5px solid rgba(177, 171, 171, 0.818)"}}
      className={`xl:mt-12  p-8 rounded-2xl flex xl:flex-row items-center flex-col-reverse gap-10 overflow-hidden`}
    >
      <motion.div
        variants={slideIn("left", "tween", 0.2, 1)}
        style={{boxShadow: "4px 4px 8px #282626, -4px -4px 15px #fdfdfd00",  border: "2.5px solid rgba(177, 171, 171, 0.818)"}}
        className='flex-[0.75] bg-transparent p-8 rounded-2xl'
      >
        <p className={styles.sectionSubText}>ابقى على تواصل</p>
        <h3 className={styles.sectionHeadText}>اتصل بنا</h3>

        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className='mt-12 flex flex-col gap-8'
        >
          <label className='flex flex-col'>
            <span className='text-white font-medium mb-4'> اكتب اسمك</span>
            <input
              type='text'
              name='name'
              value={form.name}
              onChange={handleChange}
              placeholder="ماهو اسمك  ؟"
              className='bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium'
            />
          </label>
          <label className='flex flex-col'>
            <span className='text-white font-medium mb-4'> اكتب ايميلك</span>
            <input
              type='email'
              name='email'
              value={form.email}
              onChange={handleChange}
              placeholder=" ما هو عنوان الويب الخاص بك  ؟"
              className='bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium'
            />
          </label>
          <label className='flex flex-col'>
            <span className='text-white font-medium mb-4'> اكتب الرسالة</span>
            <textarea
              rows={7}
              name='message'
              value={form.message}
              onChange={handleChange}
              placeholder=' ...  ماذا تريد ان ترسل'
              className='bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium'
            />
          </label>

          <button
            type='submit'
            className='bg-tertiary py-3 px-8 rounded-xl outline-none w-fit text-white font-bold shadow-md shadow-primary'
          >
            {loading ? "ارسال" : "ارسل"}
          </button>
        </form>
      </motion.div>

      <motion.div
        variants={slideIn("right", "tween", 0.2, 1)}
        className='xl:flex-1 xl:h-auto md:h-[550px] h-[350px]'
      >
        <EarthCanvas />
      </motion.div>
    </div>
  );
};

export default SectionWrapper(Contact, "contact");