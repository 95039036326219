
import SocialIcons from '../SocialIcons/SocialIcons'
import './Popup.css'
import img from '../../assets/pngegg (1).png'

const Popup = (props) => {

  const clicked =  () => {
    document.querySelectorAll("#close").forEach(currentItem => {
      currentItem.removeAttribute("open")
    })
    
  }
  
  return (
    <>
      <details id="close" className={props.className}>
        <summary>
          <div className="button">
            <img src={img} alt="" />
          </div>
          <div className="details-modal-overlay"></div>
        </summary>
        <div className="details-modal"> 
          <div class="details-modal-title flex justify-between">
            <h1>{props.header}</h1>
            <button className='text-red-800 text-[30px]' onClick={clicked}>X</button>
          </div>
          <div class="details-modal-content">
            <p>
              {props.content}
            </p>
          </div>
          <div className='flex details-modal-content justify-between'>
            <div>
              <SocialIcons href="https://www.facebook.com/mahmuttech" icon={<i className="fa-brands fab fa-facebook-f"></i>} />
            </div>
            <div>
              <SocialIcons href="https://www.youtube.com/@mahmuttech" icon={<i class="fa-brands fab fa-youtube"></i>} />
            </div>

            <div>
              <SocialIcons href="https://www.instagram.com/mahmuttech" icon={<i class="fa-brands fab fa-instagram"></i>} />
            </div>
          </div>
        </div>
    </details>
    </>
  )
}

export default Popup
