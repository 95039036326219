import { useEffect } from 'react';
import './CurrencyConventor.css';
import axios from 'axios'
import Popup from "../Popup/Popup";
import SideHeader from '../SideHeader/SideHeader'
import { useState } from 'react';
import Header from '../Header/Header'
import { Helmet } from 'react-helmet-async';



function CurrencyConventor() {
  let countrys_list = {
      "AUD" : "AU",
      "AED" : "AE",
      "DZD" : "DZ",
      "EGP" : "EG",
      "EUR" : "FR",
      "ILS" : "IL",
      "INR" : "IN",
      "IQD" : "IQ",
      "JOD" : "JO",
      "KWD" : "KW",
      "KYD" : "KY",
      "LBP" : "LB",
      "LYD" : "LY",
      "MAD" : "MA",
      "OMR" : "OM",
      "QAR" : "QA",
      "SAR" : "SA",
      "SYP" : "SY",
      "TND" : "TN",
      "TRY" : "TR",
      "USD" : "US",
      "YER" : "YE",
      "SDG" : "SD",
      "BHD" : "BH",
  }

  const [shortCode, setShortCode] = useState("");
  const [flagId, setflagId] = useState("");
  const [flagName, setFlagName] = useState("");
  const [letV, setLetV] = useState(0);

  useEffect(() => {
    if (navigator.geolocation) { 
      navigator.geolocation.getCurrentPosition(onSucces,onError)
    }
  }, []);

  function onSucces(position) {
    // console.log(position);
    let {latitude,longitude} = position.coords
    fetch(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=975f799b6288420f9e7058b4749a2623`)
    .then(res => res.json())
    .then(result => {
      console.log(result)
      let shortCode = result.results[0].annotations.currency.iso_code
      let flagId = result.results[0].components["ISO_3166-1_alpha-2"]
      let flagName = result.results[0].components.country
      setFlagName(flagName)
      setflagId(flagId)
      setShortCode(shortCode)
    });
  }

  function onError(error) {
    // console.log(object);
    if (error.code == 1) {
      document.getElementById("myBtn").innerHTML = "You denied the Request"
    }else if(error.code == 2){
      document.getElementById("myBtn").innerHTML = ""
    }else{
      document.getElementById("myBtn").innerHTML = "something errer"
    }
  }

  const clicked = (e) => {
    const from = document.getElementById("from").value
    const to = document.getElementById("to").value
  

    e.preventDefault();
    const moneyValue = document.getElementById("moneyValue").value
    if (moneyValue !== "") {
      axios.get(`https://api.currencyapi.com/v3/latest?apikey=h5JGUaCRUzt39qOw9X1bzhaleRrw9crt6TOt1SZu&currencies=AUD%2CAED%2CDZD%2CEGP%2CEUR%2CILS%2CINR%2CIQD%2CJOD%2CKWD%2CKYD%2CLBP%2CLYD%2CMAD%2COMR%2CQAR%2CSAR%2CSYP%2CTND%2CTRY%2CUSD%2CYER%2CSDG%2CBHD&base_currency=${from}`)
        .then((response) => {
          for (const country in response.data.data) {
            if (Object.hasOwnProperty.call(response.data.data, country)) {
              const element = response.data.data[country];
              if (element.code == to) {
                if (to === "SYP") {
                  const content = `${moneyValue} ${from} = ${(element.value  * moneyValue) * 3.530} ${to}`
                  document.getElementById("resualt").innerHTML = content
                }
                else{
                  const content = `${moneyValue} ${from} = ${element.value  * moneyValue} ${to}`
                  document.getElementById("resualt").innerHTML = content
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }else{
      alert("chose money number")
    }
  }


  useEffect(() => {
    const options = document.querySelectorAll(".flags select")
    console.log(options)

    for (let i = 0; i < options.length; i++) {
      for (const conutry in countrys_list) {
        const content = `<option value="${conutry}">${conutry}</option>`;
        options[i].insertAdjacentHTML("beforeend", content);
      }
      options[i].addEventListener("change",(e) => {
        getFlag(e.target)
      })
    }
  }, []);

    

  const getFlag = (e) => {
    for (const country in countrys_list) {
      if (e.value === country) {
        let img = e.parentElement.querySelector("img")
        // console.log(img)

        img.src = `https://flagsapi.com/${countrys_list[country]}/flat/64.png`
      }
    }
  }

  const switchs = () => {
    let from = document.getElementById("from").value
    let to = document.getElementById("to").value

    from = to

    to = from

  }

  setTimeout(() => {
    axios.get(`https://api.currencyapi.com/v3/latest?apikey=h5JGUaCRUzt39qOw9X1bzhaleRrw9crt6TOt1SZu&currencies=${shortCode}%2CEUR&base_currency=USD`)
        .then((response) => {
          document.getElementById("dolar").innerHTML = `<h1>${response.data.data.TRY.value}</h1>`;
        })
        .catch((error) => {
          console.log(error)
        })

    axios.get(`https://api.currencyapi.com/v3/latest?apikey=h5JGUaCRUzt39qOw9X1bzhaleRrw9crt6TOt1SZu&currencies=${shortCode}%2CEUR&base_currency=EUR`)
        .then((response) => {
          document.getElementById("euro").innerHTML = `<h1>${response.data.data.TRY.value}</h1>`;
        })
        .catch((error) => {
          console.log(error)
        })
  }, 1000)

  const content = 
"بإمكانك الان معرفة سعر صرف العملة بكل سهولة . فلا داعي الان للبحث أو لتحميل تطبيق معقد لمعرفة سعر الصرف .فخدمة الصراف في موقعنا هي واحدة من ضمن خدمات مجانية كثيرة في موقعنا .لا تنسى - بامكانك أن تدعمنا بشكل مجاني من خلال الاشتراك بقنواتنا على شبكات التواصل الإجتماعي";

  const sentences = content.split('.');

  const formattedContent = sentences.map((sentence, index) => (
    <p key={index}>{sentence.trim()}</p>
  ));


  return (
    <div className="App">
      <Helmet>
        <title>Mahmud.tech - currency</title>
        <meta name="description" content="مواقيت الصلاة ، صلاة الفجر ، صلاة الظهر ، صلاة العصر ، صلاة المغرب ، صلاة العشاء ، صلاة الفجر" />
      </Helmet>
      <Header element={<Popup header="تطبيق مواقيت الصلاة" content={formattedContent}  />} name="محول العملات"/>
      <SideHeader />
      <div className='cCC'>
        <form className='formCC'>
        
          <div className="valueInput">
            <input type="number" id='moneyValue' autoComplete="off" name="text" className="input" placeholder="القيمة" />
          </div>
          <br /> 
          <div className="flags">
            <div className="from">
              <h3> </h3>
              <div className='boxCC'>
                <div id='img1'>
                  <img src="https://flagsapi.com/BE/flat/64.png" alt=''/>
                </div>

                <h1 id='fromText'></h1>  
                  <select onChange={() => {setLetV(letV + 1)}} id='from' name="">

                  </select>

              </div>
            </div>
            <div onClick={switchs} className="switch">
              <ion-icon name="swap-horizontal-outline"></ion-icon>
            </div>
            <div className="to">
              <h3> </h3>
              <div className='boxCC'>
                <div id='img2'>
                  <img src={`https://flagsapi.com/BE/flat/64.png`} alt=''/>
                </div>
                <h1 id='toText'></h1>
                <select onChange={() => {setLetV(letV + 1)}} name="" id="to">
                  
                </select>
              </div>
            </div>
          </div>
        
          <div style={{transform:"translateY(-16px)"}} className="result">
            <h2 className='h2' id="resualt">0  = 0 </h2>
          </div>
        
          <button onClick={clicked} className="btn-donate"> تحويل </button>
          <br />
          <div className="mostCountries flex wrap-reverse flex-column gap-[90px]">
            <div className="oneItem flex">
            
              <table class="border-collapse border mt-8 border-slate-500 ...">
                <thead>
                  <tr>
                    <th class="border border-slate-600 px-10 ..."> <img id='flagImg' src={`https://flagsapi.com/${flagId}/flat/64.png`} alt="" /></th>
                    <th class="border border-slate-600 px-10 ...">Dolar</th>
                    <th class="border border-slate-600 px-10...">EURO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border border-slate-700 px-4 ..."> {flagName} </td>
                    <td id='dolar' class="border border-slate-700 px-4 ..."> Indiana </td>
                    <td id='euro' class="border border-slate-700 px-4 ..."> Indianapolis </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
      <Popup className="sm:flex hidden" header="سعر الصرف اليوم" content={formattedContent} />
    </div>
  );
}

export default CurrencyConventor;
