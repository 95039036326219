import React, { useState, useRef, useEffect } from "react";
import { useInterval } from "./useInterval";
import {
  CANVAS_SIZE,
  SNAKE_START,
  APPLE_START,
  SCALE,
  SPEED,
  DIRECTIONS,
  CANVAS_SIZEM,
} from "./constants";
import SideHeader from "../SideHeader/SideHeader";
import Popup from "../Popup/Popup";
import Header from "../Header/Header";
import { Helmet } from 'react-helmet-async';


const Snake = () => {
  const canvasRef = useRef();
  const [snake, setSnake] = useState(SNAKE_START);
  const [apple, setApple] = useState(APPLE_START);
  const [dir, setDir] = useState([0, -1]);
  const [speed, setSpeed] = useState(null);
  const [gameOver, setGameOver] = useState(false);
  const [score, setscore] = useState(0);

  useInterval(() => gameLoop(), speed);

  const endGame = () => {
    setSpeed(null);
    setGameOver(true);
  };

  const moveSnake = ({ keyCode }) =>{
    keyCode >= 37 && keyCode <= 40 && setDir(DIRECTIONS[keyCode]);
    console.log(dir)
  }
  const createApple = () =>
    apple.map((_a, i) => Math.floor(Math.random() * (CANVAS_SIZE[i] / SCALE)));

  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
  const content = 
  "لعبه الثعبان المشهوره على التليفونات . تستطيع الان الاستمتاع بتجربتها من موقعنا من خلال الكمبيوتر أو عن طريق اللمس من الموبايل . اللعبة تزداد سرعتها بعد حصولك على خمس نقاط وتزداد اكثر بعد 15 نقطه. وهي واحدة من عده العاب جميلة وبسيطة جمعناها لكم في مكان واحد .  لا تنسى-بإمكانكم أن تدعمونا بشكل مجاني من خلال الإشتراك بقنواتنا على شبكات التواصل";
  
  const sentences = content.split('.');

  const formattedContent = sentences.map((sentence, index) => (
    <p key={index}>{sentence.trim()}</p>
  ));

  const canvasWidth = isMobile ? CANVAS_SIZE[0] : CANVAS_SIZEM[0];
  const canvasHeight = isMobile ? CANVAS_SIZE[1] : CANVAS_SIZEM[1];
  const checkCollision = (piece, snk = snake) => {
    if (
      piece[0] * SCALE >= canvasWidth ||
      piece[0] < 0 ||
      piece[1] * SCALE >= canvasHeight || 
      piece[1] < 0
    )
    {
      const sound = '/sounds/wrong-answer-126515.mp3'
          const playSound = new Audio(sound)
          playSound.play()
      return true;

    }
    for (const segment of snk) {
      if (piece[0] === segment[0] && piece[1] === segment[1]) {
        const sound = '/sounds/wrong-answer-126515.mp3'
          const playSound = new Audio(sound)
          playSound.play()
        return true 
        
        };
    }
    return false;
  };

  const checkAppleCollision = (newSnake) => {
    if (newSnake[0][0] === apple[0] && newSnake[0][1] === apple[1]) {
      let newApple = createApple();
      while (checkCollision(newApple, newSnake)) {
        newApple = createApple();
      }
      setApple(newApple);
      setscore(score + 1);
      const sound = '/sounds/correct-2-46134.mp3'
      const playSound = new Audio(sound)
      playSound.play()
      return true;
    }
    return false;
  };

  const gameLoop = () => {
    const snakeCopy = JSON.parse(JSON.stringify(snake));
    const newSnakeHead = [snakeCopy[0][0] + dir[0], snakeCopy[0][1] + dir[1]];
    snakeCopy.unshift(newSnakeHead);
    if (checkCollision(newSnakeHead)) endGame();
    if (!checkAppleCollision(snakeCopy)) snakeCopy.pop();
    setSnake(snakeCopy);
  };

  const startGame = () => {
    setSnake(SNAKE_START);
    setApple(APPLE_START);
    setDir([0, -1]);
    setSpeed(SPEED);
    setGameOver(false);
    setscore(0);
  };

  useEffect(() => {
    const context = canvasRef.current.getContext("2d");
    context.setTransform(SCALE, 0, 0, SCALE, 0, 0);
    context.clearRect(0, 0, window.innerWidth, window.innerHeight);
    context.fillStyle = "#48484A";

    snake.forEach(([x, y]) => {
      context.beginPath();
      context.arc(x + 0.5, y + 0.2, 0.2, 0, 2 * Math.PI); // Add radius to snake's cubes
      context.fill();
      context.strokeStyle = "#E2E2E2";
      context.lineWidth = 0.9; // Change the stroke width here (e.g., 2 pixels)
      context.stroke();
    });

    context.fillStyle = "#E2E2E2";
    context.beginPath();
    context.arc(apple[0] + 0.5, apple[1] + 0.2, 0.2, 0, 2 * Math.PI); // Add radius to apple
    context.fill();
    context.strokeStyle = "#48484A";
    context.stroke();
  }, [snake, apple, gameOver]);

  useEffect(() => {
    if (score === 5) {
      setSpeed(SPEED - 40);
    }
    if (score === 15) {
      setSpeed(SPEED - 60);
    }
    if (score === 25) {
      setSpeed(SPEED - 80);
    }
  }, [score]);

  useEffect(() => {
    let touchArea = document.getElementById("touch-area");
    let output = document.getElementById("output");
    //Initial mouse X and Y positions are 0
    let mouseX,
      initialX = 0;
    let mouseY,
      initialY = 0;
    let isSwiped;
    //Events for touch and mouse
    let events = {
      mouse: {
        down: "mousedown",
        move: "mousemove",
        up: "mouseup",
      },
      touch: {
        down: "touchstart",
        move: "touchmove",
        up: "touchend",
      },
    };
    let deviceType = "";
    //Detect touch device
    const isTouchDevice = () => {
      try {
        //We try to create TouchEvent (it would fail for desktops and throw error)
        document.createEvent("TouchEvent");
        deviceType = "touch";
        return true;
      } catch (e) {
        deviceType = "mouse";
        return false;
      }
    };
  
    //Get left and top of touchArea
    let rectLeft = touchArea.getBoundingClientRect().left;
    let rectTop = touchArea.getBoundingClientRect().top;
    //Get Exact X and Y position of mouse/touch
    const getXY = (e) => {
      mouseX = (!isTouchDevice() ? e.pageX : e.touches[0].pageX) - rectLeft;
      mouseY = (!isTouchDevice() ? e.pageY : e.touches[0].pageY) - rectTop;
    };
    isTouchDevice();
    //Start Swipe
    touchArea.addEventListener(events[deviceType].down, (event) => {
      isSwiped = true;
      //Get X and Y Position
      getXY(event);
      initialX = mouseX;
      initialY = mouseY;
    });
    //Mousemove / touchmove
    touchArea.addEventListener(events[deviceType].move, (event) => {
      if (!isTouchDevice()) {
        event.preventDefault();
      }
      if (isSwiped) {
        getXY(event);
        let diffX = mouseX - initialX;
        let diffY = mouseY - initialY;
        if (Math.abs(diffY) > Math.abs(diffX)) {
          // output.innerText = diffY > 0 ? "Down" : "Up";
          if (diffY > 0) {
            setDir([0, 1])
          }else{
            setDir([0, -1])
          }
        } else {
          if (diffX > 0) {
            setDir([1, 0])
          }else{
            setDir([-1, 0])
          }
        }
      }
    });
    //Stop Drawing
    touchArea.addEventListener(events[deviceType].up, () => {
      isSwiped = false;
    });
    touchArea.addEventListener("mouseleave", () => {
      isSwiped = false;
    });
    window.onload = () => {
      isSwiped = false;
    };
  }, []);

  return (
    <>
    <Helmet>
        <title>Mahmud.tech - snake</title>
        <meta name="description" content="مواقيت الصلاة ، صلاة الفجر ، صلاة الظهر ، صلاة العصر ، صلاة المغرب ، صلاة العشاء ، صلاة الفجر" />
      </Helmet>
      <SideHeader />
      <Header element={<Popup header="تطبيق مواقيت الصلاة" content={formattedContent}  />} name="لعبة الثعبان"/>

      <div className="center" style={{ transform: "translateY(60px)" }}>
        <div
          role="button"
          className="App"
          tabIndex="0"
          id="touch-area"
          onKeyDown={(e) => moveSnake(e)}
        >
          <h1 id="output"></h1>
          <h1 style={{ textAlign: "center",color:"white" }} className="text-[25px]">
            Score: {score}
          </h1>
          <canvas
            style={{ border: "2px solid black" }}
            className="canvasContainer"
            ref={canvasRef}
            width={`${canvasWidth}px`}
            height={`${canvasHeight}px`}
          />
          <br />
          <div className="flex justify-between">
            <button className="btn-donate" onClick={startGame}>Start Game</button>
            
            <p style={{ color: "red" }}>{gameOver && <div>GAME OVER!</div>}</p>
          </div>
          <Popup className="sm:flex hidden" header=" لعبة الثعبان" content={formattedContent}  />
          <br className="flex sm:hidden" />
      <br className="flex sm:hidden" />
      <br className="flex sm:hidden" />
        </div>
      
      </div>

    </>
  );
};

export default Snake;
