import "./SideHeader.css";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import SocialIcons from '../SocialIcons/SocialIcons'
import { GiSnake } from "react-icons/gi";
import { MdPassword } from "react-icons/md";
import { FaHandScissors } from "react-icons/fa";
import { TbGoGame } from "react-icons/tb";

const SideHeader = () => {
  const [selectedLink, setSelectedLink] = useState("");
  


  function handleClick(link) {
    setSelectedLink(link);
  }
  const iconV = <i className="fa-solid fa-house"></i>
  const iconQ = <i className="fa-solid fa-qrcode"></i>
  const iconM = <i className="fa-solid fa-mosque"></i>
  const iconW = <i className="fa-solid fa-cloud-moon-rain"></i>
  const iconR = <i className="fa-solid fa-robot"></i>
  const iconG = <i class="fa-solid fa-gamepad"></i>


  function barItemClick(eo) {
    if (eo.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.classList.contains("gamesContainer")) {
      localStorage.setItem("status", "gamesC")
    }else if(eo.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.classList.contains("appsContainer")) {
      localStorage.setItem("status", "appsC")
    }else{
      localStorage.setItem("status", "homeC")
    }

    setTimeout(() => {
      window.location.reload()
    }, 10)
  }

  useEffect(() => {
    localStorage.setItem("whichApp", "home")

    if (localStorage.getItem("status") === "appsC") {
      document.getElementById('appsContainer').classList.add('slideUp')
    }
    if (localStorage.getItem("status") === "gamesC") {
      document.getElementById('gamesContainer').classList.add('slideDown')
    }
  }, []);

  const slideDown = () => {
    document.getElementById('gamesContainer').classList.add('slideDown')
    localStorage.setItem("whichApp", "games")
  }

  const slideDownUp = () => {
    document.getElementById('gamesContainer').classList.remove('slideDown')
    localStorage.setItem("whichApp", "home")
  }

  const slideUp = () => {
    document.getElementById('appsContainer').classList.add('slideUp')
    localStorage.setItem("whichApp", "apps")
  }

  const slideUpDown = () => {
    document.getElementById('appsContainer').classList.remove('slideUp')
    localStorage.setItem("whichApp", "home")
  }
  
  return (
    <>
      <div className="sideBar">
        <div className="bar">
          <div className="middle">
            <div className="barItem">
              <div className="box">
                <div onClick={slideDown}>
                  <SocialIcons icon={iconG}/>
                </div>
                {/* <p className="iconName">ألعاب</p> */}
              </div>
            </div>
            <div className="barItem">
              <NavLink
                        className="link"
                        style={{border:"2px solid black"}}
                to="/"
              >
                <div onClick={barItemClick} className="box">
                  <SocialIcons icon={iconV}/>
                </div>
              </NavLink>
            </div>
            <div className="barItem">
              <div className="box">
                <div onClick={slideUp}>
                  <SocialIcons icon={iconQ}/>
                </div>
              </div>
            </div>

            <div id="gamesContainer" className="gamesContainer">
            <div className="barItem">
                <NavLink
                          className="link"
                          style={isActive => ({
                            color: isActive ? "green" : "blue"
                          })}
                  to="/snake"
                  onClick={barItemClick}
                >
                  <div className="box">
                    <SocialIcons icon={<i class="fa-solid fa-staff-snake"></i>}/>
                  </div>
                </NavLink>
              </div>
              <div className="barItem">
                <NavLink
                          className="link"
                          style={isActive => ({
                            color: isActive ? "green" : "blue"
                          })}
                  to="/guessword"
                  onClick={barItemClick}
                >
                  <div className="box">
                    <SocialIcons icon={<i class="fa-solid fa-passport"></i>}/>
                    <button disabled className="iconName">فوازير</button>
                  </div>
                </NavLink>
              </div>
              <div className="barItem">
                <NavLink
                          className="link"
                          style={isActive => ({
                            color: isActive ? "green" : "blue"
                          })}
                  to="/fuaziir"
                  onClick={barItemClick}
                >
                  <div className="box">
                    <SocialIcons icon={iconQ}/>
                    <button disabled className="iconName">مسابقات</button>
                  </div>
                </NavLink>
              </div>
              <div className="barItem">
                <NavLink
                          className="link"
                          style={isActive => ({
                            color: isActive ? "green" : "blue"
                          })}
                  to="/tictactoe"
                  onClick={barItemClick}
                >
                  <div className="box">
                    <SocialIcons icon={<i class="fa-solid fa-x"></i>}/>
                    <button disabled className="iconName">X - O</button>
                  </div>
                </NavLink>
              </div>
              <button className="exitB" onClick={slideDownUp}><i class="fa-solid fa-arrow-right"></i></button>
            </div>

            <div id="appsContainer" className="appsContainer">
            <div className="barItem">
                <NavLink
                          className="link"
                          style={isActive => ({
                            color: isActive ? "green" : "blue"
                          })}
                  to="/azan"
                  onClick={barItemClick}
                >
                  <div className="box">
                    <SocialIcons icon={iconM}/>
                    <button disabled className="iconName">الاذان</button>
                  </div>
                </NavLink>
              </div>
              <div className="barItem">
                <NavLink
                          className="link"
                          style={isActive => ({
                            color: isActive ? "green" : "blue"
                          })}
                  to="/currencyconvertor"
                  onClick={barItemClick}
                >
                  <div className="box">
                    <SocialIcons icon={<i class="fa-solid fa-money-bill-transfer"></i>}/>
                    <button disabled className="iconName"> الصراف</button>
                  </div>
                </NavLink>
              </div>
              <div className="barItem">
                <NavLink
                          className="link"
                          style={isActive => ({
                            color: isActive ? "green" : "blue"
                          })}
                  to="/weather"
                  onClick={barItemClick}
                >
                  <div className="box">
                    <SocialIcons icon={iconW}/>
                    <button disabled className="iconName">الطقس</button>
                  </div>
                </NavLink>
              </div>
              <div className="barItem">
                <NavLink
                          className="link"
                          style={isActive => ({
                            color: isActive ? "green" : "blue"
                          })}
                  to="/chat"
                  onClick={barItemClick}
                >
                  <div className="box">
                    <SocialIcons icon={iconR}/>
                    <button disabled className="iconName">إسألني</button>
                  </div>
                </NavLink>
              </div>
              <button className="exitB" onClick={slideUpDown}><i class="fa-solid fa-arrow-right"></i></button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideHeader;
