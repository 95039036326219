import Header from "../../Components/Header/Header";
import SideHeader from "../../Components/SideHeader/SideHeader";
import './Contact.css'
import SocialIcons from '../../Components/SocialIcons/SocialIcons'
import { Helmet } from 'react-helmet-async';

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Mahmut.tech - Contact</title>
        <meta name="description" content="This is a javascript contact page for my programming project" />
      </Helmet>
      <Header  name="Azan"/>
      <SideHeader />

      <div className="contatcContainer row">
        <div className="contactItem bac col-5">
s
        </div>
        <div className="contactItem bg col-7">
          <div className="glass">
            <div className="headerText">
              <h1>Contact Us</h1>
            </div>

            <div className="form">
              <div className="nameF d-flex justify-content-center row">
                <div className="textInputWrapper col col-sm-6">
                    <input placeholder="First Name" type="text" className="textInput" />
                </div>
                <div className="textInputWrapper col col-sm-6">
                    <input placeholder="Last Name" type="text" className="textInput" />
                </div>
              </div>
              <br />
              <div className="nameF d-flex justify-content-center row">
                <div className="textInputWrapper col col-sm-6">
                    <input placeholder="Email" type="text" className="textInput" />
                </div>
                <div className="textInputWrapper col col-sm-6">
                    <input placeholder="Massage" type="text" className="textInput" />
                </div>
              </div>
              <button style={{color:"black"}}>Submit</button>
              <SocialIcons />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact
