import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Contact from './Pages/Contact/Contact';
import Portfolio from './Pages/Portfolio/Portfolio';
import Azan from './Pages/Azan/Azan';
import Weather from './Pages/Weather/Weather';
import Fuaziir from './Pages/Fuaziir/Fuaziir';
import Quran from './Pages/Quran/Quran';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'tailwindcss/tailwind.css'
import { HelmetProvider } from 'react-helmet-async';
import GuessWord from './Components/GuessWord/GuessWord'
import PaperGame from './Components/PaperGame/Paper'
import SnakeGame from './Components/SnakeGame/Snake'
import TicTacToe from './Components/TicTacToe/Xo'


import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Sonuc from './Pages/Sonuc/Sonuc';
import CurrencyConventor from './Components/CurrencyConventor/CurrencyConventor';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/portofolyo",
    element: <Portfolio />,
  },
  {
    path: "/azan",
    element: <Azan />,
  },
  {
    path: "/weather",
    element: <Weather />,
  },
  {
    path: "/fuaziir",
    element: <Fuaziir />,
  },
  {
    path: "/chat",
    element: <Quran />,
  },
  {
    path: "/sonuc",
    element: <Sonuc />,
  },
  {
    path: "/paper",
    element: <PaperGame />,
  },
  {
    path: "/tictactoe",
    element: <TicTacToe />,
  },
  {
    path: "/snake",
    element: <SnakeGame />,
  },
  {
    path: "/guessword",
    element: <GuessWord />,
  },

  {
    path: "/currencyconvertor",
    element: <CurrencyConventor />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <HelmetProvider> 
    <RouterProvider router={router} />
  </HelmetProvider>
);
serviceWorkerRegistration.register();
